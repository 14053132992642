import { useState, useReducer } from 'react';
import { chain, concat, omit } from 'lodash';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import DialogWrapper from '../dialog/wrapper';

import AddressInput from './address';
import EmailInput from './email';
import PhoneInput from './phone';

import DeleteItemForm from './deleteItem';

import { ADD_ADDRESS, UPDATE_ADDRESS, ADD_EMAIL, UPDATE_EMAIL, ADD_PHONE, UPDATE_PHONE, REMOVE_EMAIL, REMOVE_PHONE, REMOVE_ADDRESS } from '../../queries';

interface IProps {
  refetch: () => void;
  data: {
    client: {
      id: number;
      emailAddresses: [IEmail];
      phoneNumbers: [IPhone];
      addresses: [IAddress];
    };
  };
}

interface IEmail {
	id: number;
	type: string;
	email: string;
	primary: boolean;
}

interface IPhone {
	id: number;
	phone: string;
	type: string;
}

export interface IAddress {
	id: number;
	type: string;
	name: string;
	line1: string;
	line2: string;
	line3: string;
	line4: string;
	line5: string;
	city: string;
	county: string;
	postcode: string;
	country: string;
}

function Elem(props: IProps) {
  const [addresses, setAddresses] = useState(props.data.client.addresses);
  const [phoneNumbers, setPhoneNumbers] = useState(props.data.client.phoneNumbers);
  const [emailAddresses, setEmailAddresses] = useState(props.data.client.emailAddresses);

  const [addressDialogOpen, setAddressDialogOpen] = useState(false);
  //const [editAddressDialogOpen, setEditAddressDialogOpen] = useState(false);
  const [emailAddressDialogOpen, setEmailAddressDialogOpen] = useState(false);
  const [phoneDialogOpen, setPhoneDialogOpen] = useState(false);

  function reducer(state: any, action: any) {
    const {payload, type } = action;
    switch(type) {
      case 'editAddress':
        return Object.assign({}, state, {editAddressDialogOpen: true, editAddress: payload });
      case 'closeEditAddress':
        return Object.assign({}, state, { editAddressDialogOpen: false, editAddress: {} });
      case 'addEditAddressComplete':
        return Object.assign({}, state, { editAddressDialogOpen: false, })
      case 'deleteAddress':
        return Object.assign({}, state, { deleteAddressDialogOpen: true, deleteAddress: payload });
      case 'closeDeleteAddress':
        return Object.assign({}, state, { deleteAddressDialogOpen: false, deleteAddress: {} });

      case 'editEmail':
        return Object.assign({}, state, { editEmailDialogOpen: true, editEmail: payload });
      case 'closeEditEmail':
        return Object.assign({}, state, { editEmailDialogOpen: false, editEmail: {} });
      case 'deleteEmail':
        return Object.assign({}, state, { deleteEmailDialogOpen: true, deleteEmail: payload });
      case 'closeDeleteEmail':
        return Object.assign({}, state, { deleteEmailDialogOpen: false, deleteEmail: {} });

      case 'editPhone':
        return Object.assign({}, state, { editPhoneDialogOpen: true, editPhone: payload });
      case 'closeEditPhone':
        return Object.assign({}, state, { editPhoneDialogOpen: false, editPhone: {} });
      case 'deletePhone':
        return Object.assign({}, state, { deletePhoneDialogOpen: true, deletePhone: payload });
      case 'closeDeletePhone':
        return Object.assign({}, state, { deletePhoneDialogOpen: false, deletePhone: {} });

      default:
        return state;
    }
  }

  const [state, dispatch] = useReducer(reducer, {
    editAddressDialogOpen: false,
    deleteAddressDialogOpen: false,
    addressDialogOpen: false,
    editAddress: {},
    deleteAddress: {},

    editEmailDialogOpen: false,
    deleteEmailDialogOpen: false,
    editEmail: {},
    deleteEmail: {},

    editPhoneDialogOpen: false,
    deletePhoneDialogOpen: false,
    editPhone: {},
    deletePhone: {},

    addresses: [props.data.client.addresses]
  });

  const addressDialogParams = {
    title: 'Add Address',
    open: addressDialogOpen,
    toggle: setAddressDialogOpen,
    // @ts-ignore
    onComplete: (data: any) => { setAddressDialogOpen(false); setAddresses(concat([], addresses, { ...data.addAddress, addressId: data.addAddress.id })); },
    mutation: ADD_ADDRESS,
    id: props.data.client.id
  };

  const emailDialogParams = {
    title: 'Add Email address',
    open: emailAddressDialogOpen,
    toggle: setEmailAddressDialogOpen,
    // @ts-ignore
    onComplete: (data: any) => { setAddressDialogOpen(false); setEmailAddresses(concat([], emailAddresses, data.addEmail)); },
    mutation: ADD_EMAIL,
    id: props.data.client.id
  };

  const phoneDialogParams = {
    title: 'Add Phone number',
    open: phoneDialogOpen,
    toggle: setPhoneDialogOpen,
    // @ts-ignore
    onComplete: (data: any) => { setPhoneDialogOpen(false); setPhoneNumbers(concat([], phoneNumbers, data.addPhone)); },
    mutation: ADD_PHONE,
    id: props.data.client.id
  };

  const editAddressDialogParams = {
    title: 'Edit Address',
    open: state.editAddressDialogOpen,
    toggle: () => { dispatch({ type: 'closeEditAddress' }); },
    onComplete: (data: any) => {
      console.log('edit address complete'); console.log(data);
      console.log(addresses);
      const newAddresses = addresses.map(a => {
        if(a.id === data.updateAddress.id) {
          return data.updateAddress;
        }
        return a;
      });
      console.log('NewAddresses', newAddresses);
      dispatch({ type: 'closeEditAddress' });
      setAddresses((newAddresses as any));
    },
    mutation: UPDATE_ADDRESS,
    id: props.data.client.id,
    defaultVariables: state.editAddress,
    toggleOnComplete: false
  };

  const deleteAddressDialogParams = {
    title: 'Delete Address',
    open: state.deleteAddressDialogOpen,
    toggle: () => { dispatch({ type: 'closeDeleteAddress' }); },
    onComplete: (data: any) => {
      console.log('onComplete');
      console.log(data);
      setAddresses(addresses.filter(a => (a.id !== state.deleteAddress.addressId)) as any);
      dispatch({ type: 'closeDeleteAddress' });
      //setEmailAddresses(emailAddresses.filter(e => (e.email !== state.deleteEmail.email)) as any);
    },
    mutation: REMOVE_ADDRESS,
    id: props.data.client.id,
    defaultVariables: state.deleteAddress,
    toggleOnComplete: false
  };

  const editEmailDialogParams = {
    title: 'Edit Email',
    open: state.editEmailDialogOpen,
    toggle: () => { dispatch({ type: 'closeEditEmail' }); },
    onComplete: (data: any) => {
      setEmailAddresses((emailAddresses.map(e => {
        if(e.id === data.updateEmail.id) {
          return data.updateEmail;
        }
        return e;
      }) as any));
      dispatch({ type: 'closeEditEmail' });
    },
    mutation: UPDATE_EMAIL,
    id: props.data.client.id,
    defaultVariables: state.editEmail,
    toggleOnComplete: false
  };

  const deleteEmailDialogParams = {
    title: 'Delete Email',
    open: state.deleteEmailDialogOpen,
    toggle: () => { dispatch({ type: 'closeDeleteEmail' }); },
    onComplete: (data: any) => {
      dispatch({ type: 'closeDeleteEmail' });
      console.log('onComplete');
      console.log(data);
      setEmailAddresses(emailAddresses.filter(e => (e.email !== state.deleteEmail.email)) as any);
    },
    mutation: REMOVE_EMAIL,
    id: props.data.client.id,
    defaultVariables: state.deleteEmail,
    toggleOnComplete: false
  };

  const editPhoneDialogParams = {
    title: 'Edit Phone',
    open: state.editPhoneDialogOpen,
    toggle: () => { dispatch({ type: 'closeEditPhone' }); },
    onComplete: (data: any) => {
      setPhoneNumbers((phoneNumbers.map(p => {
        if(data.updatePhone.id === p.id) {
          return data.updatePhone;
        }
        return p;
      }) as any));
      dispatch({ type: 'closeEditPhone' });
    },
    mutation: UPDATE_PHONE,
    id: props.data.client.id,
    defaultVariables: state.editPhone,
    toggleOnComplete: false
  };

  const deletePhoneDialogParams = {
    title: 'Delete Phone',
    open: state.deletePhoneDialogOpen,
    toggle: () => { dispatch({ type: 'closeDeletePhone' }); },
    onComplete: (data: any) => {
      setPhoneNumbers(phoneNumbers.filter(p => (p.id !== state.deletePhone.phoneId)) as any);
      dispatch({ type: 'closeDeletePhone' });
    },
    mutation: REMOVE_PHONE,
    id: props.data.client.id,
    defaultVariables: state.deletePhone,
    toggleOnComplete: false
  };

  const AddressDialog = DialogWrapper(addressDialogParams)(AddressInput);
  const EmailDialog = DialogWrapper(emailDialogParams)(EmailInput);
  const PhoneDialog = DialogWrapper(phoneDialogParams)(PhoneInput);
  const EditAddressDialog = DialogWrapper(editAddressDialogParams)(AddressInput);
  const EditEmailDialog = DialogWrapper(editEmailDialogParams)(EmailInput);
  const EditPhoneDialog = DialogWrapper(editPhoneDialogParams)(PhoneInput);

  const DeleteEmailDialog = DialogWrapper(deleteEmailDialogParams)(DeleteItemForm);
  const DeletePhoneDialog = DialogWrapper(deletePhoneDialogParams)(DeleteItemForm);
  const DeleteAddressDialog = DialogWrapper(deleteAddressDialogParams)(DeleteItemForm);

  function doEditAddress(address: IAddress) {
    dispatch({ type: 'editAddress', payload: Object.assign({}, omit(address, ['id', '__typename']), { addressId: address.id }) });
  }

  function doDeleteAddress(address: IAddress) {
    dispatch({ type: 'deleteAddress', payload: Object.assign({}, omit(address, ['id', '__typename']), { addressId: address.id, address: address }) });
  }

  function doEditEmail(email: IEmail) {
    dispatch({ type: 'editEmail', payload: Object.assign({}, omit(email, ['id', '__typename']), { emailId: email.id }) });
  }

  function doDeleteEmail(email: IEmail) {
    dispatch({ type: 'deleteEmail', payload: Object.assign({}, omit(email, ['id', '__typename']), { emailId: email.id, email: email.email }) });
  }

  function doEditPhone(phone: IPhone) {
    dispatch({ type: 'editPhone', payload: Object.assign({}, omit(phone, ['id', '__typename']), { phoneId: phone.id }) });
  }

  function doDeletePhone(phone: IPhone) {
    dispatch({ type: 'deletePhone', payload: Object.assign({}, omit(phone, ['id', '__typename']), { phoneId: phone.id, number: phone.phone }) });
  }

	return (
		<div className="contactInfo">
			<h6>Email Address:</h6>
			<ul>
				{ emailAddresses && emailAddresses.map(e => (
          <li key={e.email}>
            <a href="mailto:{e.email}">{ e.email }</a> { e.type !== '' && e.type !== null ? (<Chip size="small" label={e.type}/>) : '' }
            <EditIcon fontSize="small" style={{ cursor: 'pointer' }} onClick={() => doEditEmail(e) }/>
            <DeleteForeverIcon fontSize="small" style={{ cursor: 'pointer' }} onClick={ () => doDeleteEmail(e) }/>
          </li>
				)) }
        <li className="text-small-italic text-grey pointer"><i onClick={ () => { setEmailAddressDialogOpen(!emailAddressDialogOpen) }}>Add an email address</i></li>
			</ul>

			<h6>Phone Number:</h6>
			<ul>
				{ phoneNumbers && phoneNumbers.map(p => (
					<li key={p.phone}>{ p.phone } { p.type !== '' && p.type !== null ? (<Chip size="small" label={p.type}/>) : '' }
            <EditIcon fontSize="small" style={{ cursor: 'pointer' }} onClick={() => doEditPhone(p) }/>
            <DeleteForeverIcon fontSize="small" style={{ cursor: 'pointer' }} onClick={ () => doDeletePhone(p) }/>
          </li>

				)) }
        <li className="text-small-italic text-grey pointer"><i onClick={ () => setPhoneDialogOpen(!phoneDialogOpen) }>Add a phone number</i></li>
			</ul>

			<h6>Address:</h6>
			<ul>
				{ addresses && addresses.map((a, i) => {
					const addressItem = chain([
						a.name,
						a.line1,
						a.line2,
						a.line3,
						a.line4,
						a.line5,
						a.city,
						a.county,
						a.postcode,
						a.country
					]).compact().join(', ').value();

					return (<li key={`clientAddress-${i}`}>
						<Grid
							container
							direction="row"
							justify="flex-start"
							alignItems="center"
							spacing={0}
						>
              <Grid item xs={10}>{ addressItem }</Grid>
              { (a.type && a.type.length>0) ? (<Grid item xs={1}><Chip size="small" label={a.type}/></Grid>) : (<Grid item xs={1}>&nbsp;</Grid>) }
              <Grid item xs={1} style={{ textAlign: 'center', cursor: 'pointer' }}>
                <EditIcon fontSize="small" style={{ cursor: 'pointer' }} onClick={() => doEditAddress(a) }/>
                <DeleteForeverIcon fontSize="small" style={{ cursor: 'pointer' }} onClick={ () => doDeleteAddress(a) }/>
              </Grid>
						</Grid>
					</li>);
				}) }
        <li className="text-small-italic text-grey pointer" onClick={ () => { setAddressDialogOpen(!state.editAddressDialogOpen) } }><i>Add an address</i></li>
			</ul>

      <AddressDialog/>
      <EditAddressDialog/>
      <EmailDialog/>
      <EditEmailDialog/>
      <DeleteEmailDialog type="Email"/>
      <PhoneDialog/>
      <EditPhoneDialog/>
      <DeletePhoneDialog type="phone"/>
      <DeleteAddressDialog type="address"/>
		</div>
	);

}

export default Elem;
