import React, { useState } from 'react';
import Spinner from '../loader/spinner';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useMutation } from '@apollo/react-hooks';
import { CREATE_TAG } from '../../queries';

export default (params: any) => {
  const [error, setError] = useState<string|undefined>();
  const [success, setSuccess] = useState<boolean>(false);
  const [create, { loading, data, error: createError }] = useMutation(CREATE_TAG, { onCompleted: onComplete });

  function submit() {
    console.log('here');
  }

  function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    setSuccess(false);
    setError(undefined);
    event.preventDefault();
    const form = new FormData(event.currentTarget);

    const name = form.get('name');
    const description = form.get('description');

    if(!name || !description) {
      setError('Name and Description are required fields');
      return;
    }

    create({ variables: { tag: { name, description }}});
  }

  function onComplete() {
    console.log('onComplete');
    (document.getElementById('tagInputForm') as HTMLFormElement).reset();
    setSuccess(true);
    params.onAdd();
  }

  return (
    <Container style={{ marginTop: '1em' }}>
      <Grid container direction="row" justify="center" alignItems="center"  spacing={2}>
        <Grid item xs={6}>
          <form onSubmit={onSubmit} id="tagInputForm">

            <div className="newClientForm" style={{ marginBottom: '2em' }}>
              <div style={{margin: '0.6em'}}><TextField name="name" label="Name" variant="outlined" fullWidth /></div>
              <div style={{margin: '0.6em'}}><TextField name="description" label="Description" variant="outlined" fullWidth /></div>

              { success && (<div style={{ margin: '2px', color: 'green' }}>New Tag successfully added</div>) }
              { error && (<div style={{ margin: '2px', color: 'red' }}>{error}</div>) }
              { createError && (<div style={{ margin: '2px', color: 'red' }}>{JSON.stringify(createError)}</div>) }

              <div style={{ margin: '0.6em' }}>
                <Button type="submit" disabled={loading} variant="contained" color="primary">
                  {loading && (<Spinner/>)} Create
                </Button>
              </div>
            </div>

          </form>
        </Grid>
      </Grid>
    </Container>
  )
}
