import React from 'react';
import { chain, compact } from 'lodash';
import Grid from '@material-ui/core/Grid';
import { Client, Sculpture, Address } from '../../types';
import Calculations from './newInvoiceSummaryCalculations';

interface IProps {
  buyer: Client
  sculpture: Sculpture
  addressId: number
  options: { removeCommission: boolean, discount: number }
}

function renderImages(images: any) {
  if(images.length ===0) {
    return (<div></div>);
  }

  return (<img key={images[0].id} src={images[0].url} alt="sculpture" width="200"/>);
}

function Summary(props: IProps) {
  const { buyer, sculpture, addressId, options } = props;
  if(!props.buyer || !props.sculpture || !props.addressId) { return (<>&nbsp;</>); }
  const address = chain(buyer.addresses)
    .find((a) => { return a.id === addressId; })
    .omit(['id', 'type', '__typename'])
    .value()

  return (
    <>
      <h3>Summary</h3>
      <Grid container xs={12}>
        <Grid item xs={6}>
          <h4>Buyer</h4>
          <div>{ buyer.name.first } { buyer.name.last }</div>
          <div>{ compact(Object.keys(address).map((k, i) => {
            const l = (address as any)[k];
            if(l === null || l.trim() === '') { return null; }
            return (<span key={`addressline${i}`}>{(address as any)[k]}<br/></span>)}
          ))}</div>
        </Grid>
        <Grid item xs={6}>
          <h4>Sculpture</h4>
          <strong>{sculpture.name}</strong>
          <p>{sculpture.sculptor.name.first} { sculpture.sculptor.name.last }</p>
          <p>{renderImages(sculpture.files)}</p>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={6}>
          <Calculations
            sculptureId={sculpture.id}
            removeCommission={options.removeCommission}
            discount={options.discount} />
        </Grid>
      </Grid>
    </>
  );

}

export default Summary;
