import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

export default (props: any) => {
  return (
    <div>
      <TextField name="type" label="Type" fullWidth value={props.values.type || ''} onChange={props.onChange}/>
      <TextField name="name" label="House Name/Number" fullWidth value={props.values.name || ''} onChange={props.onChange}/>
      <TextField name="line1" label="Line 1" fullWidth value={props.values.line1 || ''} onChange={props.onChange}/>
      <TextField name="line2" label="Line 2" fullWidth value={props.values.line2 || ''} onChange={props.onChange}/>
      <TextField name="line3" label="Line 3" fullWidth value={props.values.line3 || ''} onChange={props.onChange}/>
      <TextField name="line4" label="Line 4" fullWidth value={props.values.line4 || ''} onChange={props.onChange}/>
      <TextField name="line5" label="Line 5" fullWidth value={props.values.line5 || ''} onChange={props.onChange}/>
      <TextField name="city" label="City" fullWidth value={props.values.city || ''} onChange={props.onChange}/>
      <TextField name="county" label="County" fullWidth value={props.values.county || ''} onChange={props.onChange}/>
      <TextField name="postcode" label="Postcode" fullWidth value={props.values.postcode || ''} onChange={props.onChange}/>
      <TextField name="country" label="Country" fullWidth value={props.values.country || ''} onChange={props.onChange}/>
    </div>
  );
}
