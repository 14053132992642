import React, { Fragment } from 'react';
import { isArray } from 'lodash';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Upload from '../upload/upload';

const done: any = [];

function OrderSelector(props: { index: number, onChange: any, selected: number }) {
  return (<div>
    <label>Order
      <select name={`order${props.index}`} onChange={props.onChange}>
        <option>Choose order</option>
        <option value="1" selected={props.selected ===1}>1</option>
        <option value="2" selected={props.selected ===2}>2</option>
        <option value="3" selected={props.selected ===3}>3</option>
        <option value="4" selected={props.selected ===4}>4</option>
      </select>
    </label>
  </div>);
}

export default (props: any) => {
  console.log('NewSculpture', props);

  const handleImageUpload = (i: number) => {
    return (data: any) => {
      if(typeof data === 'undefined') {
        alert('error uploading image');
      }

      if(!done.includes(i)) {
        console.log(data);
        props.onChange({ target: { name: `image${i}`, value: data.id } }, true);
        done.push(i);
      }
    }
  }

  const removePhoto = (i: number) => {
    props.onChange({ target: { name: `image${i}`, value: '-removed-' } }, true);
    delete files[i];
  }

  let hasFiles = (isArray(props.values.files) && props.values.files.length >0);
  const files = props.values.files;

  /*
   * { hasFiles && files[0] ? (<Button color="secondary" onClick={() => removePhoto(0)}>Remove Photo</Button>) : '' }
   */

  return (<Fragment>
    <TextField name="name" label="Name" fullWidth value={props.values.name} onChange={props.onChange}/>
    <TextField name="material" label="Material" fullWidth value={props.values.material} onChange={props.onChange}/>
    <TextField name="location" label="Location" fullWidth value={props.values.location} onChange={props.onChange}/>
    <TextField name="height" label="Height" fullWidth value={props.values.height} onChange={props.onChange}/>
    <TextField name="width" label="Width" fullWidth value={props.values.width} onChange={props.onChange}/>
    <TextField name="depth" label="Depth" fullWidth value={props.values.depth} onChange={props.onChange}/>
    <TextField name="price" label="Price" fullWidth value={props.values.price} onChange={props.onChange}/>
    <TextField multiline rows={3} name="additionalInfo" label="Additional Info" fullWidth value={props.values.additionalInfo} onChange={props.onChange}/>
    <TextField name="video1" label="Video 1 URL" fullWidth value={props.values.video1} onChange={props.onChange}/>
    <TextField name="video2" label="Video 2 URL" fullWidth value={props.values.video2} onChange={props.onChange}/>
    <TextField name="order" label="order" fullWidth value={props.values.order} onChange={props.onChange}/>
    <FormControlLabel
      control={<Checkbox onChange={props.onChange} checked={props.values.displayed} name="displayed"/>}
      label="Displayed on website"/>
    <div style={{ marginTop: '1em' }}>
      <div style={{ marginBottom: '2px' }}>
        { hasFiles && files[0] ? (<img src={files[0].url} alt="image1" width="180"/>) : '' }
        <Upload label={hasFiles && files[0] ? 'Replace photo' : 'Upload photo'} name="image0" onComplete={handleImageUpload(0)} />
        { hasFiles && files[0] ? (<Button color="secondary" onClick={() => removePhoto(0)}>Remove Photo</Button>) : '' }
        <OrderSelector onChange={props.onChange} index={0} selected={props.values.order0 ? props.values.order0 : 1} />
      </div>
      <hr/>

      <div style={{ marginBottom: '2px' }}>
        { hasFiles && files[1] ? (<img src={files[1].url} alt="image1" width="180"/>) : '' }
        <Upload label={hasFiles && files[1] ? 'Replace photo' : 'Upload photo'} name="image1" onComplete={handleImageUpload(1)} />
        { hasFiles && files[1] ? (<Button color="secondary" onClick={() => removePhoto(1)}>Remove Photo</Button>) : '' }
        <OrderSelector onChange={props.onChange} index={1} selected={props.values.order1 ? props.values.order1 : 2} />
      </div>
      <hr/>

      <div style={{ marginBottom: '2px' }}>
        { hasFiles && files[2] ? (<img src={files[2].url} alt="image1" width="180"/>) : '' }
        <Upload label={hasFiles && files[2] ? 'Replace photo' : 'Upload photo'} name="image2" onComplete={handleImageUpload(2)} />
        { hasFiles && files[2] ? (<Button color="secondary" onClick={() => removePhoto(2)}>Remove Photo</Button>) : '' }
        <OrderSelector onChange={props.onChange} index={2} selected={props.values.order2 ? props.values.order2 : 3} />
      </div>
      <hr/>

      <div style={{ marginBottom: '2px' }}>
        { hasFiles && files[3] ? (<img src={files[3].url} alt="image1" width="180"/>) : '' }
        <Upload label={hasFiles && files[3] ? 'Replace photo' : 'Upload photo'} name="image3" onComplete={handleImageUpload(3)} />
        { hasFiles && files[3] ? (<Button color="secondary" onClick={() => removePhoto(3)}>Remove Photo</Button>) : '' }
        <OrderSelector onChange={props.onChange} index={3} selected={props.values.order3 ? props.values.order3 : 4} />
      </div>
      <hr/>
    </div>

  </Fragment>);
}
