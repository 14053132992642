import React from 'react';
import { Link } from 'react-router-dom';

interface IInvoice {
  id: number
  invoiceNumber: string
  sculpture: {
    name: string
  }
  exhibition: {
    name: string
  }
}

interface IProps {
  refetch: () => void
  data: {
    listInvoicesForClient: [IInvoice]
  }
}

function renderLine(props: IInvoice) {
  return (<tr>
    <td><Link to={`/invoice/${props.id}`}>{props.invoiceNumber}</Link></td>
    <td>{props.exhibition.name}</td>
    <td>{props.sculpture.name}</td>
  </tr>);
}

function ClientInvoiceList(props: IProps) {
  return (<table style={{ width: '100%' }}>
    <thead>
      <tr>
        <th style={{ textAlign: 'left', fontWeight: 'bold' }}>#</th>
        <th style={{ textAlign: 'left', fontWeight: 'bold' }}>Exhibition</th>
        <th style={{ textAlign: 'left', fontWeight: 'bold' }}>Sculpture</th>
      </tr>
    </thead>
    <tbody>
      { props.data.listInvoicesForClient.map(renderLine) }
    </tbody>
  </table>);
}

export default ClientInvoiceList;
