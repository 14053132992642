const KEY = 'crm2-key';

export const setToken = (key: string) => {
	localStorage.setItem(KEY, key)
}

export const getToken = () => {
  const params = new URLSearchParams(window.location.search);
	return localStorage.getItem(KEY) || params.get('auth');
}
