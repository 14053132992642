import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import useUpload from '../../hooks/upload';
import './style.css';

interface IProps {
  label: string
  name: string
  onComplete: (data: any) => void
  onStart?: () => void
  onError?:(error: any) => void
}

export default function(props: IProps) {
  const [filename, setFilename] = useState('');
  const {send, started, progress, complete, error, data} = useUpload(props.name);

  function onChange(e: any) {
    setFilename(e.target.files[0].name);
    send(e.target.files[0]);
  }

  if(data) {
    console.log('upload component fire onComplete');
    props.onComplete(data);
  }

  if(error) { console.log(error); alert(JSON.stringify(error)); }

  function trigger() {
    (document as any).getElementById(`file-${props.name}`).click();
  }

  return (
      <div style={{ marginTop: '0.3em' }}>
          <input
            color="primary"
            type="file"
            onChange={onChange}
            id={`file-${props.name}`}
            style={{ display: 'none', }}
          />
          <Button variant="contained" color="primary" disabled={started} size="small" onClick={() => trigger()}>
            {props.label}
          </Button>
          { (started || complete) ? (<span>&nbsp;&nbsp;<CircularProgress size={20} variant="static" value={progress.pct} color="secondary" /> {Math.round(progress.pct)}%</span>) : '' }
          <span>&nbsp;{ filename }</span>
      </div>
  );
}
