import React from 'react';
import { Query } from '@apollo/react-components';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

import Spinner from './spinner';

interface ILoaderProps {
	query: any
  params?: { [key: string]: any }
  disabled?: boolean
	lazy?: boolean
  block?: boolean
  [key: string]: any
}

interface ILoaderState {
	data: any
	isLoading: boolean
	error: any
}

//export default (props: ILoader) => (Component: any) => {
export default (loaderProps?: any) => (Component: any) =>
	class Loader extends React.Component<ILoaderProps, ILoaderState> {
    render() {
      if(this.props.disabled) { return (<>Disabled</>); }

			return (
				<Query query={this.props.query} variables={this.props.params}>
					{(props: any) => {
						const { loading, error, data, refetch } = props;

            if(loading) {
              /*
							return (<Grid container direction="row" justify="center" alignItems="center">
									<Grid item>
										<CircularProgress/>
									</Grid>
                </Grid>);
               */
              return (<Spinner/>);
						};

            if(this.props.block) { console.log(loading); console.log(data); console.log(error); return (<div>Blocked!</div>) }

						if(error) {
							return (<div>An Error Occurred {error}</div>);
						}

						return (<Component data={data} refetch={refetch} {...loaderProps} params={this.props.params}/>);
					}}
				</Query>
			);
		}
	}
