import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import get from 'lodash/get';

export default (props: any) => {
  return (
    <Grid container style={{ width: '400px' }}>
      <Grid item xs={12}><TextField name="phone" label="Phone number" fullWidth onChange={props.onChange} value={get(props, 'values.phone', '')}/></Grid>
      <Grid item xs={12}><TextField name="type" label="Type" fullWidth onChange={props.onChange} value={get(props, 'values.type', '')}/></Grid>
    </Grid>
  )
}
