import Grid from '@material-ui/core/Grid';

function Elem(props: { type: string, values: any }) {
  console.log('Values');
  console.log(props.values);
  return (
    <Grid style={{ width: '400px' }}>
      <div style={{ margin: '0 auto' }}>Are you sure you want to delete {props.type}: {props.values.email}{props.values.phone}</div>
      {props.type === 'address' ? (<>
        {props.values.address.name} {props.values.address.line1} { props.values.address.line2 } { props.values.address.line3 } {props.values.address.line4 } {props.values.address.line5 } {props.values.address.city} { props.values.address.county} {props.values.address.postcode} {props.values.address.country}
      </>) : ''}
    </Grid>
  );
}

export default Elem;
