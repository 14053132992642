import React from 'react';
import { Redirect } from 'react-router-dom';
import { useLazyQuery } from '@apollo/react-hooks';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { AUTHENTICATE } from '../../queries';
import { setToken } from '../../lib/auth';
import { useForm } from '../../hooks/form';

export default () => {
	const { values, handleChange, handleSubmit } = useForm(login);
	const [doAuth, { loading, error, data }] = useLazyQuery(AUTHENTICATE);

	function login() {
		doAuth({ variables: { email: values.email, password: values.password } });
	}

	if(error) { console.log('error', error); }
	if(data) {
		setToken(data.authenticate.key);
		return (<Redirect to="/dashboard"/>);
	}

	return (
		<form onSubmit={handleSubmit}>
			<div>
        <div style={{ margin: '0.1em 0' }}><h2>Login</h2></div>
				<TextField fullWidth type="text" label="Username" name="email" onChange={handleChange}/>
				<br/>
				<TextField fullWidth type="password" label="Password" name="password" onChange={handleChange}/>
        <div style={{ marginTop: '0.5em' }}>
          { loading ? (<div style={{ textAlign: 'center' }}><CircularProgress/></div>) : (<Button variant="contained" color="primary" type="submit">Login</Button>) }
        </div>
			</div>
		</form>
	)
}
