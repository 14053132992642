import React, { useReducer } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import { CREATE_CLIENT } from '../../queries';

const initialState = {
  salutation: '',
  first: '',
  middle: '',
  last: '',
  suffix: ''
};

function reducer(state: any, action: { type: string, payload: any }) {
  const { payload } = action;
  return Object.assign({}, state, { [payload.name]: payload.value});
}

function formatVariables(state: any) {
  return { name: state };
}

export default () => {
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [create] = useMutation(CREATE_CLIENT, { onCompleted: onComplete });
  const variables: any = formatVariables(state);

  console.log(variables);

  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    dispatch({ type: 'change', payload: { name: event.target.name, value: event.target.value }});
  }

  function onComplete(data: any) {
    history.push(`/client/?id=${data.createClient.id}`);
  }

  return (
    <Container style={{ marginTop: '1em' }}>
      <Grid container direction="row" justify="center" alignItems="center"  spacing={2}>
        <Grid item xs={6}>

            <div className="newClientForm">
              <div style={{margin: '0.6em'}}><TextField name="salutation" label="Prefix" variant="outlined" fullWidth onChange={onChange} /></div>
              <div style={{margin: '0.6em'}}><TextField name="first" label="First name" variant="outlined" fullWidth onChange={onChange} /></div>
              <div style={{margin: '0.6em'}}><TextField name="middle" label="Middle name" variant="outlined" fullWidth onChange={onChange} /></div>
              <div style={{margin: '0.6em'}}><TextField name="last" label="Last name" variant="outlined" fullWidth onChange={onChange} /></div>
              <div style={{margin: '0.6em'}}><TextField name="suffix" label="Suffix" variant="outlined" fullWidth onChange={onChange} /></div>
              <div style={{ margin: '0.6em' }}>
                <Button  variant="contained" color="primary" onClick={() => create({ variables })}>Create</Button>
              </div>
            </div>

        </Grid>
      </Grid>
    </Container>
  )
}
