import {applyMiddleware, compose, createStore} from 'redux';
import {routerMiddleware} from 'connected-react-router';
import {createBrowserHistory} from 'history';
import reducers from './stores/index';

export const history = createBrowserHistory();

export default function configureStore(preloadedState) {
  const store = createStore(
    reducers(history),
    preloadedState,
    compose(applyMiddleware(routerMiddleware(history))),
  );

  return store;
}
