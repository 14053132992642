import React from 'react';
import { Mutation } from 'react-apollo';
import { isUndefined } from 'lodash';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';

interface IDialogProps {
  title: string
  open: boolean
  toggle: (open: boolean) => void
  onComplete: (data?: any) => void
  id: number
  mutation: any
  formatter?: (state: any) => {}
  defaultVariables?: any
  dontUpdateState?: boolean
  toggleOnComplete?: boolean
  submitButtonLabel?: string
  submitButtonColor?: any
  noSubmit?: boolean
}

export default (dialogProps: IDialogProps) => (Component: any) =>
  class DialogWrapper extends React.Component<any, any> {
    private toggle: (open: boolean) => void;

    constructor(props: any) {
      super(props);
      this.state = {
        id: dialogProps.id,
        loading: false,
        ...dialogProps.defaultVariables
      };

      this.toggle = dialogProps.toggle;
      this.handleChange = this.handleChange.bind(this);
      this.onComplete = this.onComplete.bind(this);
      this.doSubmit = this.doSubmit.bind(this);
    }


    hack = { id: dialogProps.id };

    handleChange(event: React.ChangeEvent<HTMLInputElement>, updateState=true) {
      if(event && event.target) {
        //console.log('change', event.target.name, event.target.value, event.target.checked);
        if(event && typeof event.persist === 'function') {
          event.persist();
        }

        // work out if we need to create or append to an array in state.
        //
        const hasArray = event.target.name.includes('[]');
        if(hasArray) {
          const parts = event.target.name.split('.');
          parts.forEach((p: string) => {
            if(p.includes('[]')) {
            }
          });
        } else {
          let value: string|boolean = event.target.value;
          if(event.target.type === 'checkbox') {
            value = event.target.checked;
          }

          if(!this.props.dontUpdateState || updateState) {
            this.setState({ ...this.state, [event.target.name]: value }, () => { console.log(this.state); });
          } else {
            this.hack = Object.assign({}, {...this.hack}, {[event.target.name]: event.target.value});
          }
        }
      } else {
        //console.log('WRAPPER handleChange');
        //console.log(event);
      }
    }

    onComplete(data: any): void {
      this.setState({ loading: false });
      if(isUndefined(dialogProps.toggleOnComplete) || (!isUndefined(dialogProps.toggleOnComplete) && dialogProps.toggleOnComplete)) {
        this.toggle(false);
      }
      dialogProps.onComplete(data);
    }

    onError(err: any): void {
      console.log('onError', err);
      this.setState({ loading: false });
      alert(JSON.stringify(err));
    }

    doSubmit(newState: any, submit: any) {
      this.setState(newState, submit);
    }

    render() {
      let submitLabel = dialogProps.submitButtonLabel ? dialogProps.submitButtonLabel : 'Save';
      let submitColor = dialogProps.submitButtonColor ? dialogProps.submitButtonColor : 'primary';
      let variables = this.state;
      if(typeof dialogProps.formatter === 'function') {
        variables = dialogProps.formatter(variables);
      }

      return (<Dialog open={dialogProps.open} onEscapeKeyDown={() => { this.toggle(false); }} onClose={() => { this.toggle(false); }}>
        <Mutation mutation={dialogProps.mutation} variables={{ ...variables }} onCompleted={ this.onComplete }>
          { (submit: () => void) => (
            <div>
              <DialogTitle>{dialogProps.title}</DialogTitle>
              <DialogContent>
                <Component {...this.props} onChange={this.handleChange} values={this.state}/>
              </DialogContent>
              <DialogActions>
                <Button variant="contained" onClick={() => this.toggle(false)}>Cancel</Button>
            { this.state.loading ? (<CircularProgress/>) : (<Button onClick={() => {
              dialogProps.noSubmit ? this.onComplete(variables) : this.doSubmit({ loading: true, ...this.hack }, submit) }
            } variant="contained" color={submitColor}>{ submitLabel }</Button>)}
              </DialogActions>
            </div>)
          }
        </Mutation>
      </Dialog>);
    }
  }
