import React, { useReducer } from 'react';
import { useMutation } from '@apollo/react-hooks';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import { filter, find, findIndex, get, isNumber, map, orderBy, isObject, omit, compact } from 'lodash';

import { ADD_SCULPTURE, UPDATE_SCULPTURE, DELETE_SCULPTURE } from '../../queries';
import DialogWrapper from '../dialog/wrapper';
import Sculpture, { ISculpture } from './single';
import NewSculpture from './new';
import DeleteSculpture from './deleteSculpture';
import Spinner from '../loader/spinner';

interface IExhibition {
	id: number
	name: string
	year: string
}

function reducer(state: any, action: any) {
  const { payload, type } = action;
  switch(type) {
    case 'edit':
      const sculpture = Object.assign({}, {...payload});
      payload.videos.forEach((item: string, index: number) => {
        sculpture[`video${index+1}`] = item;
      });
      return Object.assign({}, state, {open: true}, {sculpture: {...sculpture}});
    case 'close':
      return Object.assign({}, state, { open: false });
    case 'changeExhibition':
      const { exhibition } = payload;
      const newList = orderBy(filter(state.allSculptures, (s) => { return s.exhibition.id === exhibition }), ['order'], ['asc']) as [ISculpture];
      return Object.assign({}, state, { list: newList, exhibition, exhibitionName: get(find(state.exhibitions, { id: exhibition }), 'name', '') });
    case 'editSculptureComplete':
      const sculptureIndex = findIndex(state.list, { id: payload.id });
      state.list[sculptureIndex] = payload;
      return Object.assign({}, state, { open: false });
    case 'newSculptureOpen':
      return Object.assign({}, state, { newSculptureOpen: true });
    case 'newSculptureComplete':
      // check for this item in the list already.
      //
      if(findIndex(state.allSculptures, { id: payload.id}) >-1) {
        return Object.assign({}, state, { newSculptureOpen: false });
      }

      state.allSculptures.push(payload);
      state.list.unshift(payload);
      return Object.assign({}, state, { newSculptureOpen: false });
    case 'closeNewSculpture':
      return Object.assign({}, state, { newSculptureOpen: false });
    case 'deleteSculpture':
      return Object.assign({}, state, { deleteSculptureOpen: true, deleteSculpureId: payload.id });
    case 'closeDeleteSculpture':
      return Object.assign({}, state, { deleteSculptureOpen: false, deleteSculpureId: null });
    case 'deleteSculptureComplete':
      const id = get(payload, 'removeSculpture.id');
      // remove the sculpture from the main list.
      const list = filter(state.list, (i) => { return i.id !== id; });
      // remove the sculpture from the all list.
      const allSculptures = filter(state.allSculptures, (i) => { return i.id !== id; });
      return Object.assign({}, state, {
        deleteSculptureOpen: false,
        deleteSculpureId: null,
        list,
        allSculptures
      });
    default:
      return state;
  }
}

function SculptureList(props: { refetch: any, data: { client: { id: number, sculptures: [ISculpture] }, exhibitions: [IExhibition] }}) {
  const DEFAULT_EXHIBITION_ID = 11;
	const sculptures = props.data.client.sculptures;
	const exhibitions = props.data.exhibitions;

  const list = orderBy(filter(sculptures, (s) => { return s.exhibition.id === DEFAULT_EXHIBITION_ID }), ['order'], ['asc']) as [ISculpture]

  const [state, dispatch] = useReducer(reducer, {
    newSculptureOpen: false,
    open: false,
    sculpture: {},
    allSculptures: sculptures,
    loading: false,
    list,
    exhibitions,
    exhibition: DEFAULT_EXHIBITION_ID,
    exhibitionName: 'onform-22',//get(find(exhibitions, { id: 1 }), 'name', '')
    deleteSculptureOpen: false,
    deleteSculpureId: null
  });

  function editSculpture(id: number) {
    const sculpture = find(state.list, { id });
    dispatch({ type: 'edit', payload: {...sculpture} });
  }

  function closeEditSculpture() {
    dispatch({ type: 'close' });
  }

  function handleExhibitionChange(event: React.ChangeEvent<{ value: any }>) {
    dispatch({ type: 'changeExhibition', payload: { exhibition: event.target.value }});
  }

  function addSculptureFormatter(values: any) {
    return {
      id: values.id,
      sculpture: {
        name: values.name,
        exhibition: { id: values.exhibitionId },
        material: values.material,
        location: values.location,
        width: parseFloat(values.width),
        depth: parseFloat(values.depth),
        height: parseFloat(values.height),
        weight: parseFloat(values.weight),
        catalogNumber: values.catalogNumber,
        price: parseFloat(values.price),
        currency: values.currency,
        displayed: values.displayed,
        photos: filter([values.image0, values.image1, values.image2, values.image3], isNumber).map((id, index) => ({ id, sculptureOrder: get(values, `order${index}`) })),
        videos: compact([values.video1, values.video2, values.video3, values.video4]),
        additionalInfo: values.additionalInfo,
        order: 0
      }
    }
  }

  function editSculptureFormatter(values: any) {
    return {
      id: values.id,
      sculpture: {
        id: values.id,
        name: values.name,
        exhibition: { id: values.exhibitionId },
        material: values.material,
        location: values.location,
        width: parseFloat(values.width),
        depth: parseFloat(values.depth),
        height: parseFloat(values.height),
        weight: parseFloat(values.weight),
        catalogNumber: values.catalogNumber,
        price: parseFloat(values.price),
        currency: values.currency,
        displayed: values.displayed,
        photos: filter(map([
          [get(values, 'files[0]'), get(values, 'image0')],
          [get(values, 'files[1]'), get(values, 'image1')],
          [get(values, 'files[2]'), get(values, 'image2')],
          [get(values, 'files[3]'), get(values, 'image3')]
        ], (item, index) => {
          // New ...
          //
          if(item[1] && item[1] === '-removed-') { return null; }
          if(item[1]) {
            const order = get(values, `order${index}`);
            const out: any = { id: item[1] }
            if(order && order !== null) {
              out.sculptureOrder = parseInt(order);
            } else {
              out.sculptureOrder = index +1;
            }

            return out;
          }
          else if(item[0]) {
            // Existing...
            //
            const order = get(values, `order${index}`);
            const out = Object.assign({}, omit(item[0], '__typename'));

            if(order && order !== null) {
              out.sculptureOrder = parseInt(order);
            } else {
              out.sculptureOrder = index +1;
            }

            if(out.sculptureOrder === null) {
              delete out.sculptureOrder;
            }

            return out;
          }
          else { return null; }
        }), isObject),
        videos: compact([
          values.video1 ? values.video1 : get(values, 'videos[0]'),
          values.video2 ? values.video2 : get(values, 'videos[1]')
        ]),
        additionalInfo: values.additionalInfo,
        order: parseInt(values.order)
      }
    }
  }

  const handleSculptureSelect = (id: number) => {
    editSculpture(id);
  }

  function handleDeleteSculpture(id: number) {
    dispatch({ type: 'deleteSculpture', payload: { id }});
  }

  const NewSculptureDialog = DialogWrapper({
    title: 'New Sculpture',
    open: state.newSculptureOpen,
    toggle: () => { dispatch({ type: 'closeNewSculpture'}) },
    onComplete: (data) => { dispatch({ type: 'newSculptureComplete', payload: data.addSculpture }); }, //newSculptureComplete,
    mutation: ADD_SCULPTURE,
    formatter: addSculptureFormatter,
    id: props.data.client.id,
    defaultVariables: { exhibitionId: state.exhibition },
    toggleOnComplete: false
  })(NewSculpture);

  const EditSculptureDialog = DialogWrapper({
    title: 'Edit Sculpture',
    open: state.open,
    toggle: closeEditSculpture,
    onComplete: (data) => { dispatch({ type: 'editSculptureComplete', payload: data.updateSculpture[0] }) },
    mutation: UPDATE_SCULPTURE,
    formatter: editSculptureFormatter,
    id: state.sculpture.id,
    defaultVariables: { exhibitionId: state.exhibition, ...state.sculpture },
    toggleOnComplete: false
  })(NewSculpture);


  const DeleteSculptureDialog = DialogWrapper({
    title: 'Delete Sculpture',
    open: state.deleteSculptureOpen,
    toggle: () => { dispatch({ type: 'closeDeleteSculpture'}); },
    onComplete: (data) => {
      dispatch({ type: 'deleteSculptureComplete', payload: data });
    },
    mutation: DELETE_SCULPTURE,
    id: state.deleteSculpureId,
    toggleOnComplete: false,
    submitButtonLabel: "DELETE",
    submitButtonColor: "secondary"
  })(DeleteSculpture);

  if(state.loading) { return (<Spinner/>); }

	return (
		<div>
			<div style={{ marginBottom: '1em' }}>
				<InputLabel id="demo-simple-select-label">Select an Exhibition</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={state.exhibition}
          onChange={handleExhibitionChange}>
					{ exhibitions.map(e => (<MenuItem value={e.id} key={e.id}>{e.name}</MenuItem>)) }
        </Select>
			</div>

      <div className="text-grey" style={{ margin: '1em', textAlign: 'center' }}>
        <Button color="primary" size="small" onClick={() => dispatch({ type: 'newSculptureOpen'}) }>Add Sculpture to {state.exhibitionName}</Button>
      </div>

      <div key={`sculpture-exhibition-${state.exhibition}`}>
        { state.list.map((s: ISculpture) => (
              <Sculpture {...s} onSelect={handleSculptureSelect} onDelete={handleDeleteSculpture}/>
        ))}
      </div>
      <NewSculptureDialog/>
      <EditSculptureDialog/>
      <DeleteSculptureDialog/>
		</div>
	);
}

export default SculptureList;
