import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';

import Spinner from '../loader/spinner';
import { ALL_TAGS } from '../../queries';

import NewTagForm from './new';

export default () => {
  const history = useHistory();

  const { data, loading, error, refetch } = useQuery(ALL_TAGS);
  console.log(data);

  if(loading) { return (<Spinner />); }

  return (<div>
    <NewTagForm onAdd={() => refetch()} />
    <table>
      <tbody>
      { data.tags.map((t: any) => (
        <tr key={t.id}>
          <td style={{ cursor: 'pointer' }} onClick={()=>{history.push(`/list-tags/?tag=${t.name}`)}}><strong>{t.name}</strong></td>
          <td>{t.description}</td>
      </tr>)) }
    </tbody>
    </table>
  </div>);
}
