import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import Card from '@material-ui/core/Card';
import TextField from '@material-ui/core/TextField';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { concat } from 'lodash';

import { ADD_NOTE } from '../../queries';

interface INote {
  id: number
	title: string
	body: string
}

export default (props: { notes: [INote], id: number }) => {
  const [notes, setNotes] = useState(props.notes);
  const [note, setNote] = useState('');

  function onCompleted(data: any) {
    console.log(data);
    setNotes((concat([], data.addNote, notes) as any));
    setNote('');
  }

  const [addNote, { loading }] = useMutation(ADD_NOTE, {onCompleted});

  function doAddNote () {
    addNote({ variables: {
      id: props.id,
      body: note
    }});
  }

  const handleNoteTextChange = (event: any) => { setNote(event.target.value); }

	return (
    <div>
      { loading ? (<CircularProgress/>) : (
			<Card style={{ marginBottom: '1em' }}>
				<CardContent>
					<TextField multiline rows="4" variant="outlined" placeholder="Add a note" style={{ width: '100%' }} onChange={handleNoteTextChange} />
				</CardContent>
				<CardActions>
					<Button color="primary" variant="outlined" onClick={doAddNote}>Add Note</Button>
				</CardActions>
		</Card>)}

    { notes && notes.map((n, i) => {
      const key = `note-${n.id}`;
        return (<Card variant="outlined" key={key} style={{ marginBottom: '1em' }}>
          <CardContent>
            <h3>{ n.title }</h3>
            { n.body }
          </CardContent>
        </Card>);
      })}
      </div>
	)
}
