import * as React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { getToken } from './lib/auth';
import Dashboard from './views/dashboard';
import ClientNew from './views/clientNew';
import InvoiceNew from './views/invoiceNew';
import InvoiceView from './views/invoiceView';


const ProtectedRoute = ({ component: Component, ...args }: any) => {
	const authorised = getToken();

	return (<Route {...args} render={(props) => (
		authorised ? <Component {...props} />
		: <Redirect to="/login"/>
	)}/>);
};

export default ({...props}) => {
  return (
    <Switch>
			<ProtectedRoute component={Dashboard} />
    </Switch>
  );
};
