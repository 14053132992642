import React from 'react';
import { useQuery } from '@apollo/react-hooks';

import { formatCurrency } from './lib';
import { TableRow } from './view';
import Spinner from '../loader/spinner';
import { CALCULATE_INVOICE } from '../../queries';

interface IProps {
  sculptureId: number
  removeCommission: boolean
  discount: any
}

export default (props: IProps) => {
  if(!props.sculptureId) { return (<>&nbsp;</>); }

  const {
    loading: calculationsLoading,
    error: calculationsError,
    data: calculationsData
  } = useQuery(CALCULATE_INVOICE, { variables: {
    sculptureId: props.sculptureId,
    removeCommission: props.removeCommission,
    discount: parseFloat(props.discount)
  }});

  console.log(calculationsLoading, calculationsError, calculationsData);
  if(calculationsError) { alert(JSON.stringify(calculationsError)) }
  if(calculationsLoading) { return (<Spinner/>); }
  if(calculationsData) {
    const { gross, net, commission, vat, artistPayment, discounted } = calculationsData.calculateInvoice;
    console.log({ gross, commission,vat, artistPayment, discounted });
    return (
      <div>
        <h4>Calculations</h4>
        <table style={{ width: '100%' }}>
          <tbody>
            { discounted >0 ? (
              <>
                <TableRow key="originalPrice" label="Original Price" value={(gross + discounted)} formatter={formatCurrency}/>
                <TableRow key="discounted" label="Discounted" value={discounted} formatter={formatCurrency}/>
              </>
            ) : null }
          <TableRow key="gross" label="Gross" value={gross} formatter={formatCurrency}/>
          { vat >0 ? (
            <>
              <TableRow key="net" label="Net" value={net} formatter={formatCurrency}/>
              <TableRow key="vat" label="VAT" value={vat} formatter={formatCurrency} />
          </>) : null}
          <TableRow key="commission" label="Commission" value={commission} formatter={formatCurrency}/>
          <TableRow key="artpayment" label="Artist Payment" value={artistPayment} formatter={formatCurrency}/>

          </tbody>
        </table>
      </div>
    );
  }

  return (<>&nbsp;</>);
}
