import React, { useState } from 'react';

export const useForm = (callback?: (values: any) => void, initialState?: any) => {
	const [ values, setValues ] = useState((initialState || {} as any));

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		if(event) {
			event.preventDefault();
		}
		if(typeof callback === 'function') {
			return callback(values);
		}
	}

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		event.persist();
		setValues({ ...values, [event.target.name]: event.target.value });
	}

	return { handleSubmit, handleChange, values };
}
