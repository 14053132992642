import React, { useState } from 'react';
import nl2br from 'react-nl2br';
import Button from '@material-ui/core/Button';

import DialogWrapper from '../dialog/wrapper';
import SculptorFieldsInput from './sculptorFieldsInput';

import { SET_CUSTOM_FIELDS } from '../../queries';

import { get, find } from 'lodash';

export default (props: any) => {
  const [editOpen, setEditOpen] = useState(false);
  const customFields = get(props, 'data.client.customFields', []);

  const _websiteBio = get(find(customFields, { name: 'biography' }), 'value', '');
  const _catalogBio = get(find(customFields, { name: 'catalog-biography' }), 'value', '');
  const _video = get(find(customFields, { name: 'sculptor-youtube' }), 'value', '');
  const _website = get(find(customFields, { name: 'sculptor-website' }), 'value', null);
  const _portrait = get(find(customFields, { name: 'sculptor-portrait' }), 'value', null);
  const _mainImage = get(find(customFields, { name: 'sculptor-main-img' }), 'value', null);
  const _displayName = get(find(customFields, { name: 'sculptor-display-name' }), 'value', null);

  const [portrait, setPortrait] = useState(_portrait);
  const [mainImage, setMainImage] = useState(_mainImage);
  const [website, setWebsite] = useState(_website);
  const [websiteBio, setWebsiteBio] = useState(_websiteBio);
  const [catalogBio, setCatalogBio] = useState(_catalogBio);
  const [video, setVideo] = useState(_video);
  const [displayName, setDisplayName] = useState(_displayName);

  const EditFieldsDialog = DialogWrapper({
    title: 'Sculptor Information',
    open: editOpen,
    toggle: setEditOpen,
    onComplete: (data: any) => {
      console.log('EditSculptorFields Complete'); console.log(data);
      setPortrait(get(data, 'setCustomFields.portrait'));
      setMainImage(get(data, 'setCustomFields.mainImage'));
      setWebsite(get(data, 'setCustomFields.website'));
      setWebsiteBio(get(data, 'setCustomFields.webBio'));
      setCatalogBio(get(data, 'setCustomFields.catalogBio'));
      setVideo(get(data, 'setCustomFields.video'));
      setDisplayName(get(data, 'setCustomFields.displayName'));

      console.log(displayName);
    },
    mutation: SET_CUSTOM_FIELDS,
    id: props.data.client.id,
    defaultVariables: {
      displayName: displayName,
      webBio: websiteBio,
      catalogBio: catalogBio,
      website: website,
      video: video,
      mainImage: mainImage,
      portrait: portrait
    }
  })(SculptorFieldsInput);

  function webBioMarkup() {
    return { __html: websiteBio };
  }

  return (<div>


      <div className="text-grey" style={{ margin: '1em', textAlign: 'center' }}>
        <Button color="primary" size="small" onClick={() => setEditOpen(true) }>Edit</Button>
      </div>

      <div>
        <div style={{ marginBottom: '10px' }}>
          <h4>Display Name</h4>
          { displayName || '<default>' }
        </div>
        <div style={{ marginBottom: '10px' }}>
          <h4>Website Biography</h4>
          <div dangerouslySetInnerHTML={webBioMarkup()}/>
        </div>
        <div style={{ marginBottom: '10px' }}>
          <h4>Website Address</h4>
          { website || '<none>' }
        </div>
        <div style={{ marginBottom: '10px' }}>
          <h4>Video</h4>
          { video || '<none>' }
        </div>
        <div style={{ marginBottom: '10px' }}>
          <h4>Portrait</h4>
          { portrait ? (<img src={portrait} alt="portrait" width="180"/>) : ('<none>')}
        </div>
        <div style={{ marginBottom: '10px' }}>
          <h4>Main Image</h4>
          { mainImage ? (<img src={mainImage} alt="mainImage" width="180"/>) : ('<none>')}
        </div>
      </div>


    <EditFieldsDialog dontUpdateState/>

  </div>);

}
