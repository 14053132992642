import * as React from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { Switch, Route } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import ClientList from '../components/clientList/list';
import ClientView from '../components/client/view';
import Loader from '../components/loader/loader';

import InvoiceNewView from './invoiceNew';
import InvoiceList from './listInvoices';
import ClientNew from './clientNew';
import Tags from './tags';

import { GET_CLIENT, RECENT_CLIENTS } from '../queries';

const ClientViewComponent = (props:any) => {
  console.log('ClientViewComponent', props);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const clientId = useQuery().get('id');
  if(clientId === null) { return (<div>hello</div>); }
  return (<ClientView query={GET_CLIENT} params={{ id: parseInt(clientId, 10) }}/>);
};

const InternalRoute = ({ component: Component, ...args }: any) => {
  console.log('internalRoute', args);
  return (<Route {...args } render={(props) => (<Component {...props }/>)}/>);
}

const Dashboard = (props: any) => {
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const clientId = useQuery().get('id');
  const hashTag = useQuery().get('tag');

  const ClientListView = Loader()(ClientList);
  console.log('props');
  console.log(props);

  return (
		<Grid container>
			<Grid item xs={2} style={{ backgroundColor: '#fff', borderRight: '1px solid #efefef', maxHeight: '90%', overflow: 'auto'}}>
        <ClientListView query={RECENT_CLIENTS} params={{ text: props.filter || (hashTag ? '#'+hashTag : ''), type: props.filterType  }} />
			</Grid>
      <Grid item xs={10} style={{ maxHeight: '100%', overflow: 'auto' }}>

        <Switch>
          <InternalRoute path="/new-person" component={ClientNew} />
          <InternalRoute path="/list-tags" component={Tags} />
          <InternalRoute path="/new-invoice" component={InvoiceNewView}/>
          <InternalRoute path="/list-invoices" component={InvoiceList}/>
          <InternalRoute component={ClientViewComponent} clientId={clientId}/>
        </Switch>

      </Grid>
		</Grid>
  )
}
/*

        { clientId ? (<ClientView query={GET_CLIENT} params={{ id: parseInt(clientId, 10) }}/>) : (<div style={{ margin: '10px' }}>&nbsp;</div>)}
 */

const mapStateProps = (state: any) => {
  console.log(state);
  return { filter: state.search.get('filter'), filterType: state.search.get('filterType') }
};

export default connect(mapStateProps)(Dashboard);
