import { gql } from 'apollo-boost';

export const AUTHENTICATE = gql`
	query Authenticate($email: String! $password: String!) {
		authenticate(email: $email password: $password) {
			key
		}
	}
`;

export const RECENT_CLIENTS = gql`
	query RecentClients($text: String, $type: String) {
		clients(filter: { text: $text type: $type }) {
			id
			name {
				salutation
				first
				middle
				last
				suffix
			}
			fullName
			tags {
				id
				name
			}
		}
	}
`;

export const GET_CLIENT_SCULPTURES = gql`
	query ClientSculptures($id: Int!) {
		client(id: $id) {
      id
			sculptures {
				id
				name
				exhibition {
					id
					name
				}
				material
				location
        width
        depth
				height
				weight
				catalogNumber
				price
				currency
				displayed
				files {
					id
          url
          sculptureOrder
        }
        videos
        additionalInfo
        order
			}
		},
		exhibitions {
			id
			name
			year
		}
	}
`;

export const GET_CLIENT = gql`
	query Client($id: Int!) {
		client(id: $id) {
      id
			name {
				salutation
				first
				middle
				last
				suffix
			}
			fullName
			tags {
				id
				name
			}
      notes {
        id
				title
				body
			}
			createdAt
			updatedAt
		}
	}
`;

export const GET_CLIENT_CONTACT_INFO = gql`
  query Client($id: Int!) {
    client(id: $id) {
      id
			name {
				salutation
				first
				middle
				last
				suffix
			}
			fullName
			tags {
				id
				name
			}
      addresses {
        id
				type
				name
				line1
				line2
				line3
				line4
        line5
        city
				county
				postcode
				country
			}
      emailAddresses {
        id
				type
				email
				primary
			}
      phoneNumbers {
        id
				type
				phone
			}
    }
  }
`;

export const UPDATE_CLIENT = gql`
  mutation UpdateClient($id: Int! $emailAddresses: [IEmail] $name: IClientName) {
    updateClient(id: $id emailAddresses: $emailAddresses name: $name) {
      id
      name {
        salutation
        first
        middle
        last
        suffix
      }
      emailAddresses {
        email
        type
      }
    }
  }
`;

export const REMOVE_CLIENT = gql`
  mutation RemoveClient($id: Int!) {
    removeClient(id: $id) {
      success
    }
  }
`;

export const ADD_ADDRESS = gql`
  mutation AddAddress($id: Int! $type: String $name: String $line1: String $line2: String $line3: String $line4: String $line5: String $city: String $county: String $postcode: String $country: String) {
    addAddress(ClientId: $id type: $type name: $name line1: $line1 line2: $line2 line3: $line3 line4: $line4 line5: $line5 city: $city county: $county postcode: $postcode country: $country) {
    id
    type
    name
    line1
    line2
    line3
    line4
    line5
    city
    county
    postcode
    country
    }
  }
`;

export const UPDATE_ADDRESS = gql`
  mutation UpdateAddress($id: Int! $addressId: Int! $type: String $name: String $line1: String $line2: String $line3: String $line4: String $line5: String $city: String $county: String $postcode: String $country: String) {
    updateAddress(ClientId: $id id: $addressId type: $type name: $name line1: $line1 line2: $line2 line3: $line3 line4: $line4 line5: $line5 city: $city county: $county postcode: $postcode country: $country) {
    id
    type
    name
    line1
    line2
    line3
    line4
    line5
    city
    county
    postcode
    country
    }
  }
`;

export const REMOVE_ADDRESS = gql`
  mutation RemoveAddress($id: Int! $addressId: Int!) {
    removeAddress(ClientId: $id addressId: $addressId) {
      success
    }
  }
`;

export const ADD_EMAIL = gql`
  mutation AddEmail($id: Int! $email: String! $type: String $primary: Boolean) {
    addEmail(ClientId: $id email: $email type: $type primary: $primary) {
      id
      email
      type
      primary
    }
  }
`;

export const UPDATE_EMAIL = gql`
  mutation UpdateEmail($id: Int! $emailId: Int! $type: String $email: String) {
    updateEmail(ClientId: $id id: $emailId type: $type email: $email) {
      id
      email
      type
    }
  }
`;

export const REMOVE_EMAIL = gql`
  mutation RemoveEmail($emailId: Int!) {
    removeEmail(id: $emailId) {
      success
    }
  }
`;

export const ADD_PHONE = gql`
  mutation AddPhone($id: Int! $phone: String! $type: String) {
    addPhone(ClientId: $id phone: $phone type: $type) {
      id
      phone
      type
    }
  }
`;

export const REMOVE_PHONE = gql`
  mutation RemovePhone($phoneId: Int!) {
    removePhone(id: $phoneId) {
      success
    }
  }
`;

export const UPDATE_PHONE = gql`
  mutation UpdatePhone($id: Int! $phoneId: Int! $phone: String! $type: String) {
    updatePhone(ClientId: $id id: $phoneId phone: $phone type: $type) {
      id
      phone
      type
    }
  }
`;

export const GET_SCULPTOR_FIELDS = gql`
query GetSculptorFields($id: Int!) {
  client(id: $id) {
    id
    fullName
    customFields {
      name
      type
      value
    }
  }
}`;

export const REMOVE_CLIENT_TAG = gql`
mutation RemoveClientTag($clientId: Int! $tagId: Int!) {
  removeClientTag(clientId: $clientId tagId: $tagId) {
    success
  }
}`;

export const SET_CLIENT_TAGS = gql`
mutation SetClientTags($id: Int! $tags: [Int]!) {
  setClientTags(clientId: $id tags: $tags) {
    id
    name
    description
  }
}`

export const UPLOAD_FILE = gql`
mutation Upload($file: Upload!) {
  upload(file: $file) {
    filename
    url
  }
}`;

export const CREATE_TAG = gql`
mutation AddTag($tag: IITag!) {
  addTag(tag: $tag) {
    id
    name
    description
  }
}
`;

export const ALL_TAGS = gql`
query Tags {
  tags {
    id
    name
    description
  }
}
`;

export const SET_CUSTOM_FIELDS = gql`
mutation SetCustomFields($id: Int! $website: String $webBio: String $catalogBio: String $video: String $portrait: String $mainImage: String $displayName: String) {
  setCustomFields(id:$id website:$website webBio:$webBio catalogBio:$catalogBio video:$video portrait:$portrait mainImage:$mainImage displayName: $displayName) {
    webBio
    catalogBio
    video
    website
    portrait
    mainImage
    displayName
  }
}
`;

export const ADD_NOTE = gql`
mutation AddNote($id: Int! $title: String $body: String) {
  addNote(ClientId: $id title: $title body: $body) {
    id
    title
    body
  }
}
`;

export const ADD_SCULPTURE = gql`
mutation AddSculpture($id: Int! $sculpture: ISculpture) {
  addSculpture(ClientId: $id sculpture: $sculpture) {
    id
    name
    material
    location
    width
    depth
    height
    catalogNumber
    price
    displayed
    files {
      id
      name
      url
      sculptureOrder
    }
    exhibition {
      id
    }
    videos
    additionalInfo
    order
  }
}
`;

export const UPDATE_SCULPTURE = gql`
mutation UpdateSculpture($id: Int $sculpture: ISculpture $sculptures:[ISculpture]) {
  updateSculpture(id: $id sculpture: $sculpture sculptures: $sculptures) {
    id
    name
    material
    location
    width
    depth
    height
    catalogNumber
    price
    displayed
    files {
      id
      name
      url
      sculptureOrder
    }
    videos
    additionalInfo
    order
    exhibition {
      id
    }
  }
}
`;

export const DELETE_SCULPTURE = gql`
mutation DeleteSculpture($id: Int) {
  removeSculpture(id: $id) {
    success
    id
  }
}
`;

export const GET_INVOICE = gql`
query GetInvoice($id: Int!) {
  getInvoice(id: $id) {
    id
    invoiceNumber
    sculpture {
      name
      price
      catalogNumber
      files {
        url
      }
      sculptor {
        emailAddresses {
          email
        }
      }
    }
    sculptorName
    buyerName
    buyerAddress
    buyerEmail
    buyerPhone
    vatNumber
    originalPrice
    gross
    price
    vat
    discount
    paid
    notes
    paymentNotes
    dueNow
    purchaseOrder
    createdAt
    sentAt
    paidAt
    commissionStatementRaisedAt
  }
}
`;

export const GET_CLIENT_INVOICES = gql`
query ClientInvoices($id: Int!) {
  listInvoicesForClient(id: $id) {
    id
    invoiceNumber
    sculpture {
      name
    },
    exhibition {
      name
    }
  }
}
`;

export const ADD_INVOICE = gql`
mutation AddInvoice($invoice: IInvoice!) {
  addInvoice(invoice: $invoice) {
    id
  }
}
`;

export const SEARCH_SCULPTURES = gql`
query SearchSculpture($exhibition: Int! $name: String) {
  searchSculptures(exhibition: $exhibition name: $name) {
    id
    name
    catalogNumber
    files {
      url
    }
    sculptor {
      fullName
      name {
        first
        last
      }
    }
  }
}
`;

export const INVOICE_SEARCH_BUYER = gql`
	query RecentClients($text: String) {
		clients(filter: { text: $text type: "name" }) {
			id
			name {
				salutation
				first
				middle
				last
				suffix
			}
      fullName,
		}
	}
`;

export const INVOICE_GET_BUYER = gql`
  query Client($id: Int!) {
    client(id: $id) {
			name {
				salutation
				first
				middle
				last
				suffix
			}
      fullName,
      addresses {
        id
        type
        name
        line1
        line2
        line3
        line4
        line5
        city
        county
        postcode
        country
      }
      phoneNumbers {
        id
        type
        phone
      }
      emailAddresses {
        id
        type
        email
      }
    }
  }
`;

export const CALCULATE_INVOICE = gql`
query CalculateInvoice($sculptureId: Int! $removeCommission: Boolean $discount: Float) {
  calculateInvoice(sculptureId: $sculptureId removeCommission: $removeCommission discount: $discount) {
    gross
    net
    vat
    commission
    artistPayment
    discounted
  }
}
`;

export const CREATE_INVOICE = gql`
mutation CreateInvoice($invoice: IInvoice) {
  addInvoice(invoice: $invoice) {
    id
    invoiceNumber
    clientId
    sculptureId
  }
}
`;

export const LIST_INVOICES = gql`
query ListInvoices($exhibition: Int! $artistId: Int) {
  listInvoices(exhibition: $exhibition artistId: $artistId) {
    id
    invoiceNumber
    ExhibitionId
    ClientId
    ArtistId
    sculptureId
    sculpture {
      name
      catalogNumber
    }
    sculptorName
    buyerName
    gross
    paid
    net
    vat
    commission
    discounted
    artistPayment
    createdAt
    sentAt
    paidAt
    commissionStatementRaisedAt
  }
  sculptureCount(exhibitionId: $exhibition) {
    count
  }
}
`;

export const EXHIBITION_ARTISTS = gql`
query ExhibitionArtists($exhibitionId: Int!) {
  exhibitionArtists(exhibitionId: $exhibitionId) {
    id
    name
  }
}
`;

export const SEND_INVOICE = gql`
mutation SendInvoice($id: Int!, $message: String!, $recipients: String!) {
  sendInvoice(id: $id, recipients: $recipients, message: $message) {
    success
    message
  }
}
`;

export const CREATE_CLIENT = gql`
mutation CreateClient($name: IClientName) {
  createClient(name: $name) {
    id
  }
}
`;

export const GET_TEMPORARY_TOKEN = gql`
query TemporaryToken {
  temporaryAuth {
    token
  }
}
`;

export const SET_INVOICE_PROPS = gql`
  mutation UpdateInvoiceProps($id: Int!, $paidAt: String, $notes: String, $commissionStatementDate: String ) {
    updateInvoiceProps(id: $id, paidAt: $paidAt, notes: $notes, commissionStatementRaisedAt: $commissionStatementDate) {
      success
      message
    }
  }
`;
