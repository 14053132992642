import React, { useState, ChangeEvent, useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import isNil from 'lodash/isNil';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';

import { SearchSetFilter } from '../../stores/actions';
import { Button, MenuItem, Select } from '@material-ui/core';

function Input (props: any) {
  console.log(props);
  const [ filterText, setFilterText ] = useState('');
  const [ filterType, setFilterType ] = useState('name');

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  useEffect(() => {
    const tag = new URLSearchParams(props.history).get('tag');
    if(!isNil(tag) && tag !== filterText) {
      setFilterText(`#${tag}`);
      props.dispatch(SearchSetFilter('', 'name'));
    }
  }, [props.history]);

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    setFilterText(event.target.value);
  }

  function handleKeyDown(event: React.KeyboardEvent) {
    if(event.key === 'Enter') {
      submit();
    }
  }

  function submit() {
    props.dispatch(SearchSetFilter(filterText, filterType));
  }

/*

      <InputBase style={{ color: '#000' }} placeholder="Search..." onChange={handleChange} onKeyDown={handleKeyDown} value={filterText} />

  return (
    <div>
      <div className="searchIcon" style={{ display: 'inline-block', marginRight: '0.3em', fontSize: '0.5em' }}><SearchIcon /></div>
    </div>
  )
	*/

  return (
    <>
			<Grid item>
				<SearchIcon />
			</Grid>
			<Grid item xs={2}>
				<TextField id="input-with-icon-grid" label="Search..." style={{ width: '100%' }} onChange={handleChange} onKeyDown={handleKeyDown} value={filterText} />
      </Grid>
      <Grid item xs={2}>
        <Select value={filterType} label="Type" onChange={(e) => setFilterType((e.target.value as string))}>
          <MenuItem value="name">Name</MenuItem>
          <MenuItem value="address">Address</MenuItem>
          <MenuItem value="email">Email</MenuItem>
          <MenuItem value="phone">Phone</MenuItem>
          <MenuItem value="tag">Tag</MenuItem>
        </Select>
        <Button variant="contained" onClick={submit} style={{ marginLeft: '1em' }} size="small">Search</Button>
      </Grid>
    </>
	)
}

const mapStateToProps = (state: any) => {
  return {
    filter: state.search.get('filter'),
    tag: state.search.get('tag')
  };
};

export default connect(mapStateToProps)(Input);
