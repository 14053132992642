import React, { useState } from 'react';
// import { useMutation } from '@apollo/react-hooks';
import Chip from '@material-ui/core/Chip';
import Add from '@material-ui/icons/Add';

import DialogWrapper from '../dialog/wrapper';
import TagsInput from './tagsInput';
import { SET_CLIENT_TAGS } from '../../queries';

export default (props: { tags: any[], clientId: number }) => {
  const [tags, setTags] = useState(props.tags);
  //const [removeTag] = useMutation(REMOVE_CLIENT_TAG);
  const [tagDialogOpen, setTagDialogOpen] = useState(false);


  const tagsDialogParams = {
    title: 'Update Tags',
    open: tagDialogOpen,
    toggle: setTagDialogOpen,
    onComplete: (data: any) => { console.log('UpdateTagsOnComplete'); setTags(data.setClientTags); console.log(data); /*setTagDialogOpen(false); setTags((concat([], tags, data.addClientTags) as any));*/ },
    mutation: SET_CLIENT_TAGS,
    id: props.clientId
  };

  const TagsDialog = DialogWrapper(tagsDialogParams)(TagsInput);

  /*
  async function removeClientTag(tagId: number) {
    try {
      console.log(tagId);
      await removeTag({ variables: { clientId: props.clientId, tagId } });
      setTags(filter(tags, (t) => { return t.id !== tagId }));
    } catch(e) {
      console.log(e);
    }
  }
  console.log('TAGS HERE 123');
  console.log(tags);
  */

  return (
    <div key="tag">
      {tags && tags.length >0 && tags.map((t: any) => {
        if(t) {
        return (
          <Chip
            key={t.id}
            label={t.name}
            size="small"
            style={{ margin: '0.2em 0.5em' }}
            variant="outlined"
          />
        );
      } else { return null; }
      })}
      <Chip variant="outlined" size="small" color="primary" label="Update tags" onClick={() => setTagDialogOpen(true)} icon={<Add />} />
      <TagsDialog dontUpdateState selected={tags.map(t => { return t.id })}/>
    </div>
  );
};
