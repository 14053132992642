import React, { useReducer, useEffect, useMemo } from 'react';
import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks';
import { Mutation } from 'react-apollo';
import { useHistory } from 'react-router-dom';
import { get, find, compact, debounce } from 'lodash';
import Select from 'react-select';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import Spinner from '../loader/spinner';
import InvoiceBuyerInfo from './buyerInfo';
import RenderSummary from './newInvoiceSummary';
import NewInvoiceOptions from './NewInvoiceOptions';
import BuyerAddress from './buyerAddress';
import './new.css';

import { ADD_INVOICE, INVOICE_SEARCH_BUYER, SEARCH_SCULPTURES, INVOICE_GET_BUYER } from '../../queries';

const EXHIBITION_ID = 11;

const NewClient = () => (<div>New Client</div>);

function formatInvoiceVariables(input: any) {
  const a = input.address;
  const address = a ? compact([
    get(a,'name'), get(a, 'line1'), get(a, 'line2'), get(a, 'line3'),
    get(a, 'line4'), get(a, 'line5'), get(a, 'line6'),
    get(a, 'city'), get(a, 'postcode'), get(a, 'county'), get(a, 'country')]).join('\n') : ''

  const emails = get(input, 'buyer.emailAddresses[0]') ? input.buyer.emailAddresses.map((i: any) => { return i.email }).join('|') : '';
  const phones = get(input, 'buyer.phoneNumbers[0]') ? input.buyer.phoneNumbers.map((i: any) => { return i.phone }).join('|') : '';

  const o = {
    invoice: {
      clientId: get(input, 'buyerId'),
      sculptureId: get(input, 'sculpture.id'),
      removeCommission: get(input, 'options.removeCommission', false),
      discount: parseFloat(get(input, 'options.discount', 0)),
      buyerAddress: address,
      buyerEmail: emails,
      buyerPhone: phones,
      buyerName: `${get(input, 'buyer.name.first', '').trim()} ${get(input, 'buyer.name.last', '').trim()}`
    }
  };

  return o;
}

function reducer(state: any, action: any) {
  const { payload, type } = action;
  switch(type) {
    case 'onOptionsChange':
      return Object.assign({}, state, { ...payload });
    case 'onBuyerChange':
      return Object.assign({}, state, { buyerId: payload.data.id });
    case 'onBuyerSelect':
      return Object.assign({}, state, { buyer: payload });
    case 'onBuyerInputChange':
      if(payload.text.length <3) { return state; }
      return Object.assign({}, state, { buyerSearchString: payload.text });
    case 'onSculptureChange':
      return Object.assign({}, state, { sculpture: payload.data });
    case 'onAddressChange':
      const id = parseInt(payload);
      return Object.assign({}, state, { addressId: id, address: find(state.buyer.addresses, { id }) });
    default:
      return state;
  }
}

function generateBuyerOption(c: { id: number, fullName: string, name: { first: string, last: string }}) {
  return { value: c.id, label: `${c.name.first} ${c.name.last}`, data: c };
}

function generateSculptureOption(s: { id: number, name: string, catalogNumber: string, files: [{url: string}], sculptor: { fullName: string}}) {
  const label = `${s.name} (#${s.catalogNumber}) (${s.sculptor.fullName})`;
  return { value: s.id, label, data: s };
}

const NewInvoice = () => {
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, {
    buyerSearchString: '',
    buyer: null,
    buyerId: null,
    sculptureSearchString: '',
    sculpture: null,
    addressId: null,
    address: null,
    newBuyer: false,
    options: {
      removeCommission: false,
      discount: 0
    },
    buyerLoading: false,
    buyerError: null,
    buyerData: [],
    sculptureLoading: false,
    sculptureError: null,
    sculptureData: []
  });


  const onCompleted = (data: any) => {
    history.push(`/invoice/${data.addInvoice.id}`);
  }

  const [addInvoice, { loading: addInvoiceLoading, data: addInvoiceData, error: addInvoiceError}] = useMutation(ADD_INVOICE, { onCompleted });
  const [getBuyer, { loading: buyerLoading, data: buyerData, error: buyerError }] = useLazyQuery(INVOICE_GET_BUYER);
  const [getBuyers, { loading: buyersLoading, data: buyersData, error: buyersError }] = useLazyQuery(INVOICE_SEARCH_BUYER);
  const debouncedGetBuyers = useMemo(() => debounce(getBuyers, 400), []);

  useEffect(() => {
    if(state.buyerSearchString.length >0) {
      debouncedGetBuyers({ variables: { text: state.buyerSearchString }});
    }
  }, [state.buyerSearchString]);

  useEffect(() => {
    if(state.buyerId) {
      getBuyer({ variables: { id: state.buyerId }});
    }
  }, [state.buyerId]);

  useEffect(() => {
    if(buyerData) {
      dispatch({ type: 'onBuyerSelect', payload: buyerData.client });
    }
  }, [buyerData]);

  const {
    loading: sculptureLoading,
    error: sculptureError,
    data: sculptureData
  } = useQuery(SEARCH_SCULPTURES, { variables: { exhibition: EXHIBITION_ID, name: state.sculptureSearchString }});

  if(buyersError) { alert('buyerError' + JSON.stringify(buyersError)); }
  if(buyerError) { alert('buyerError' + JSON.stringify(buyerError)); }
  if(sculptureError) { alert('sculptureError' + JSON.stringify(sculptureError)); }

  const buyerItems = get(buyersData, 'clients', []);
  const buyerOptions: any = buyerItems.length >0 ? buyerItems.map(generateBuyerOption) : [];

  const sculptureItems = get(sculptureData, 'searchSculptures', []);
  const sculptureOptions: any = sculptureItems.length >0 ? sculptureItems.map(generateSculptureOption) : [];

  function doAddInvoice() {
    if(!addInvoiceLoading) {
      addInvoice({ variables: formatInvoiceVariables(state) });
    }
  }

  function onAddressChange(event: React.FormEvent<HTMLSelectElement>) {
    dispatch({ type: 'onAddressChange', payload: event.currentTarget.value });
  }

  function onOptionsChange(option: string) {
    return (event: React.FormEvent<HTMLInputElement>) => {
      const value = option === 'removeCommission' ? event.currentTarget.checked : event.currentTarget.value;
      const options = Object.assign({}, { options: Object.assign({}, state.options, { [option]: value })});
      dispatch({ type: 'onOptionsChange', payload: options });
    }
  }

  return (<div>
    <h2>New Invoice</h2>
    <div>

    <NewInvoiceOptions onChange={onOptionsChange} discount={state.options.discount} removeCommission={state.options.removeCommission}/>

    <Grid container spacing={1}>
      <Grid item xs={12}><h3>Buyer</h3></Grid>
      <Grid item xs={6}>
        <Select
          isDisabled={false}
          isLoading={buyersLoading}
          isSearchable={true}
          onInputChange={ (text: string) => dispatch({ type: 'onBuyerInputChange', payload: { text }}) }
          options={buyerOptions}
          onChange={ (option:any) => dispatch({ type: 'onBuyerChange', payload: { ...option }}) }
        />
        { (state.buyer && buyerLoading) ? (<div><Spinner/></div>) : (<></>) }
        { (state.buyer && !buyerLoading) ? (<InvoiceBuyerInfo key={state.buyerId ? `buyer-${state.buyerId }` : `no-buyer`} buyer={state.buyer} onAddressChange={onAddressChange}/>) : (<>&nbsp;</>) }

        { state.addressId ? <BuyerAddress key={`buyerAddressId-${state.addressId}`} address={state.address}/> : (<>&nbsp;</>) }
      </Grid>

      <Grid item xs={6} style={{ display: 'none' }}>
        <Button>New Buyer</Button>
      </Grid>

      { state.newBuyer ? (<NewClient/>) : (<></>) }
    </Grid>
    <hr/>

    <h3>Sculpture</h3>
    <Select
      isDisabled={false}
      isLoading={sculptureLoading}
      isSearchable={true}
      options={sculptureOptions}
      onChange={ (option:any) => dispatch({ type: 'onSculptureChange', payload: { ...option }}) }
    />
    <hr/>

    <RenderSummary addressId={state.addressId} buyer={state.buyer} sculpture={state.sculpture} options={{...state.options}}/>

    { state.addressId && state.buyer && state.sculpture ? (
        <div style={{ marginTop: '2em' }}>
          <span onClick={() => doAddInvoice()} style={{ cursor: 'pointer', color: '#fff', backgroundColor: '#54983b', border: '1px solid #54983b', padding: '3px 4px', borderRadius: '4px' }}>
            Create Invoice {addInvoiceLoading ? (<Spinner/>) : (null)}
          </span>
        </div>)
      : (null) }

  </div>

  </div>);
}

export default NewInvoice;
