import React from 'react';
import { filter, includes, isArray } from 'lodash';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import Loader from '../loader/loader';

import { ALL_TAGS } from '../../queries';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const TagsList = (props: { data: { tags: [{id: number, name: string}] }, selected: [number], onChange: any, values: any }) => {
  const allTags: any = props.data.tags;
  let defaultValue = [];

  if(props.values && isArray(props.values.tags)) {
    defaultValue = filter(allTags, o => {
      return props.values.tags.includes(o.id);
    });
  } else if(props.selected && props.selected.length >0) {
    defaultValue = filter(allTags, o => {
      return props.selected.includes(o.id);
    });
  }

  function onChange(e: any, value: any) {
    props.onChange({ target: { name: 'tags', value: (value as any).map((t: any) => { return t.id; }) } });
  }

  return (
    <Grid container style={{ width: '400px' }}>
      <Autocomplete
        multiple
        id="checkboxes-tags-demo"
        options={allTags}
        disableCloseOnSelect
        defaultValue={defaultValue}
        getOptionLabel={(option: any) => option.name}
        renderOption={(option: any, state: any) => (
          <React.Fragment>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={state.selected || includes(props.selected, option.id)}
            />
            {option.name}
          </React.Fragment>
        )}
        style={{ width: 500 }}
        renderInput={(params: {}) => (
          <TextField
            {...params}
            variant="outlined"
            label="Tags"
            placeholder="Tags"
          />
        )}
        onChange={(a: any, v: any) => { onChange(a, v); }}
      />
    </Grid>
  );
};


export default (props: any) => {
  console.log('TAGS OUTER');
  console.log(props);
  const TagsListView = Loader(props)(TagsList);
  return (
    <TagsListView query={ALL_TAGS} params={{}}/>
  )
}
