import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import NewInvoiceForm from '../components/invoice/new';

function InvoiceNew() {

  return (
    <Container style={{ marginTop: '1em', marginBottom: '100px' }}>
      <Grid container direction="row" justify="center" alignItems="center"  spacing={2}>
        <Grid item xs={10}>
          <Paper style={{ padding: '1em' }}>
            <NewInvoiceForm/>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  )
}

export default InvoiceNew;
