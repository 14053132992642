import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import NewClientForm from '../components/client/new';

export default () => {

  return (
    <Container style={{ marginTop: '1em', marginBottom: '100px' }}>
      <Grid container direction="row" justify="center" alignItems="center"  spacing={2}>
        <Grid item xs={10}>
          <Paper style={{ padding: '1em' }}>
            <NewClientForm/>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  )
}
