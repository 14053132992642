import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/react-hooks';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import { get, isNil, pickBy } from 'lodash';

import { formatCurrency } from './lib';
import Spinner from '../loader/spinner';
import { GET_INVOICE, SEND_INVOICE, SET_INVOICE_PROPS } from '../../queries';

import './view.scss';
import spinner from '../loader/spinner';
import { Button, Input, TextareaAutosize } from '@material-ui/core';

interface IInvoice {
  id: number
  invoiceNumber: string
  createdAt: string
  buyerName: string
  buyerAddress: string
  buyerPhone: [string]
  buyerEmail: [string]
  sculptorName: string
  sculpture: {
    name: string
    price: number
    catalogNumber: string
    files: [{ url: string }]
    sculptor: {
      emailAddresses: { email: string }[]
    }
  }
  price: number
  vat: number | null
  vatNumber: string | null
  paid: boolean
  notes: string | null
  paymentNotes: string | null
  purchaseOrder: string | null
  dueNow: number | null
  gross: number
  originalPrice: number
  discount: number
  sentAt: string
  paidAt: string
  commissionStatementRaisedAt: string
}

function ViewInvoice() {
  let params = useParams();

  const {loading, error, data} = useQuery(GET_INVOICE, { variables: { id: parseInt((params as any).id) }})

  if(loading) { return (<div><Spinner/></div>); }
  if(error) { return (<div>ERROR<p>{JSON.stringify(error)}</p></div>); }
  if(data) {
    const invoice: IInvoice = data.getInvoice;
    const urlParams = new URLSearchParams(window.location.search);
    const isPreview = urlParams.get('auth');

    document.title = `${invoice.invoiceNumber} - ${invoice.sculptorName} - ${invoice.sculpture.name} - ${invoice.buyerName}`;
    return (<>
      <Grid container direction="row">
        { !isPreview && (<SendInvoice {...invoice} />) }
        { !isPreview && (<Options {...invoice} />) }
        <Grid item xs={12}><img src="/onform-pms-green-grey-large.png" alt="logo"/></Grid>
        <Grid item xs={6} style={{ marginTop: '10px' }}>
          <Details {...invoice}/>
        </Grid>
        <Grid item xs={2}/>
        <Grid item xs={4} justify="flex-end"><Customer {...invoice}/></Grid>

        <Grid container style={{ marginTop: '10px' }}>
          <Grid item xs={6}>
            <Item {...invoice } isPreview={isPreview}/>
          </Grid>
          <Grid item xs={6}>
            <ItemImage { ...invoice } />
          </Grid>
        </Grid>

        <Grid container style={{ marginTop: '10px' }} xs={12}>
          <Grid item xs={4}>
            { invoice.discount >0 ? (<Discount {...invoice}/>) : (null) }
            { invoice.vatNumber ? (<Vat { ...invoice }/>) : (null) }
            <Summary { ...invoice } />
          </Grid>
          <Grid item xs={1}/>
          <Grid item xs={7}>
            <Payment/>
          </Grid>
        </Grid>

        <Grid container xs={12}>
          <Grid item xs={12}>
            <Footer {...invoice }/>
          </Grid>
        </Grid>
      </Grid>
    </>);
  }

  return (<div>you should not see this...</div>);
}

const Details = (props: IInvoice) => (
  <>
    <h3>Invoice</h3>
    <table>
      <TableRow label="Invoice number" value={props.invoiceNumber}/>
      <TableRow label="Date" value={moment.utc(props.createdAt).format('YYYY-MM-DD')}/>
      { props.purchaseOrder ? (<TableRow label="Purchase Order" value={props.purchaseOrder}/>) : null }
    </table>
  </>
);

const Customer = (props: IInvoice) => (
  <>
    <h3>Customer</h3>
    <strong>{props.buyerName}</strong>
    <p>{props.buyerAddress.split('\n').map((i: any) => (<span>{i}<br/></span>))}</p>
    <p>{ props.buyerPhone.length >0 ? <ListWithTitle title="Phone" items={props.buyerPhone}/> : (<></>) }</p>
    <p>{ props.buyerEmail.length >0 ? <ListWithTitle title="Email" items={props.buyerEmail}/> : (<></>) }</p>
  </>
);

const ListWithTitle = (props: { title: string, items: [string]}) => (
  <>
    <h4>{props.title}</h4>
    {props.items.map((i: any) => (<span>{i}<br/></span>)) }
  </>
);

const Item = (props: IInvoice & { isPreview: string | null }) => (
  <>
    <h3>Item</h3>
    <table>
      <TableRow label="Name" value={props.sculpture.name}/>
      <TableRow label="Artist" value={props.sculptorName}/>
      <TableRow label="Catalog Number" value={props.sculpture.catalogNumber}/>
      { props.notes && !props.isPreview ? (<TableRow label="Notes" value={props.notes}/>) : null }
    </table>
  </>
);

const ItemImage = (props: IInvoice) =>  {
  let url = props.sculpture.files[0].url;
  if(props.sculpture.files[0].url.includes('https://www.onformsculpture.co.uk/')) {
    const toInsert = 'wp-content/webp-express/webp-images/doc-root';
    url = url.substring(0, 34) + toInsert + '/' + url.substring(34) + '.webp';
  }
  return (
  <div style={{ textAlign: 'center' }}>
    <img src={`${url}`} alt="sculpture" title="sculpture" height="150" style={{ padding: '2px', border: '1px solid rgb(204, 204, 204)' }}/>
  </div>);
};

const Vat = (props: IInvoice) => (
  <>
    <h3>VAT</h3>
    <table>
      <TableRow label="VAT" value={`${formatCurrency(props.vat)}`}/>
      <TableRow label="Net Cost" value={`${formatCurrency(props.gross - props.vat!)}`} className="bold"/>
    </table>
  </>
);

const Discount = (props: IInvoice) => (
  <>
    <h3>Discount</h3>
    <table>
      <TableRow label="Original Price" value={`${formatCurrency(props.originalPrice)}`}/>
      <TableRow label="Discount" value={`${formatCurrency(props.originalPrice - props.gross)}`}/>
    </table>
  </>
);

const Summary = (props: IInvoice) => {
  return (
  <>
    <h3>Summary</h3>
    <table>
      <TableRow label="Cost" value={`${formatCurrency(props.gross)}`}/>
      <TableRow label="Total Due" value={`${formatCurrency(props.gross)}`} className={props.dueNow ? '' : 'bold' }/>
      { props.dueNow ? (<TableRow label="Due Now" value={`${formatCurrency(props.dueNow)}`} className="bold"/>) : null }
      {props.paymentNotes ? (<TableRow label="" value={props.paymentNotes}/>) : null }
      {props.paid ? (<TableRow label="" value="PAID IN FULL" className="paid"/>) : null }
    </table>
  </>)
};

const Payment = () => (
  <>
    <h3>Payment</h3>
    <table>
      <TableRow label="Payment terms" value="7 days"/>
      <TableRow label="Bank" value="HSBC Bank"/>
      <TableRow label="Account name" value="onform sculpture"/>
      <TableRow label="Account number" value="51358901" className="monospace"/>
      <TableRow label="Sort code" value="40 16 46" className="monospace"/>
      <TableRow label="IBAN" value="GB81HBUK40164651358901" className="monospace"/>
      <TableRow label="SWIFT" value="HBUKGB4B" className="monospace"/>
    </table>
    <p>
      Please quote the invoice number on any payments<br/>
      Please make cheques payable to <strong>onform sculpture</strong>
    </p>
  </>
);

export const TableRow = (props: { label: string, value: any, className?: string, formatter?: (i: any) => void }) => {
  let { label, value, className, formatter } = props;
  if(formatter) { value = formatter(value); }

  return (
  <tr>
    <td style={{ minWidth: '100px' }}>{label}</td>
    <td className={className}>{value}</td>
  </tr>);
};

const Footer = (props: IInvoice) => (
  <div className="footer">
    <div><strong>on form</strong> sculpture - Asthall Manor, Burford, Oxfordshire, OX18 4HW</div>
    <div>Tel: 01993 824319 - Email: info@onformsculpture.co.uk - www.onformsculpture.co.uk</div>
    <div>Sold for and on behalf of <strong>{props.sculptorName}.</strong>{props.vatNumber ? (<span> VAT Registration {props.vatNumber}</span>) : (null)}</div>
  </div>
)

const SendInvoice = (props: IInvoice) => {
  const [ alreadySent, setAlreadySent ] = useState(!isNil(props.sentAt) ? true : false);
  const [ doSubmit, { error, data, loading }] = useMutation(SEND_INVOICE, { onCompleted: () => { setAlreadySent(true) }});
  const { success, message } = get(data, 'sendInvoice', {});
  const buyerEmails = [...props.buyerEmail].join('; ');
  const sculptorEmails = [...props.sculpture.sculptor.emailAddresses.map(e => (e.email))].join('; ');

  const defaultMessage = `\n\nThank you for your purchase of ${props.sculpture.name} by ${props.sculptorName}\n\nI have attached your invoice and also copied in the artist, so they can contact you to discuss delivery requirements.\n\nIf you have any questions or need anything further, please do not hesitate to get in touch.\n\nBest Wishes`;

  function submit(e: any) {
    e.preventDefault();
    const data = new FormData(e.target);
    const emails = data.getAll('emails');
    const message = data.get('message');

    const recipients = emails.reduce((a,b) => {
      return [].concat(a).concat(b.toString().split(';') as any);
    }, []);

    doSubmit({ variables: { id: props.id, message, recipients: recipients.join(';') }});
  }

  if(error) {
    alert(error.toString());
  }

  return (<div style={{ marginBottom: '3em' }}>
    <h1>Send Invoice</h1>
    <form onSubmit={submit} style={{ marginTop: '1em' }}>
    <ul>
      <li>
        <h3>Customer Emails</h3>
        <p style={{ fontSize: '0.9em' }}>
          Separate emails with a semicolon eg: name@example.com; some@where.com
        </p>
        <textarea name="emails" defaultValue={buyerEmails} style={{ width: '500px', height: '60px', fontSize: '1em', padding: '3px' }}></textarea>
      </li>
      <li>
        <h3>Sculptor Emails</h3>
        <p style={{ fontSize: '0.9em' }}>
          Separate emails with a semicolon eg: name@example.com; some@where.com
        </p>
        <textarea name="emails" defaultValue={sculptorEmails} style={{ width: '500px', height: '60px', fontSize: '1em', padding: '3px' }}></textarea>
      </li>
      <li><textarea name="message" defaultValue={defaultMessage} style={{ width: '500px', height: '200px', fontSize: '1em', padding: '3px' }}></textarea></li>
      { alreadySent && (
        <li style={{ marginTop: '0.5em', marginBottom: '0.5em', color: '#FFA500'}}>
            <label style={{ cursor: "pointer"}}>
              <strong>Note:</strong> this invoice has already been sent
              <br/>
              Tick the box to allow you to send it again <input type="checkbox" id="allowSend" onChange={() => setAlreadySent(!alreadySent)}/>
            </label>
        </li>
      )}
      <li>
          { loading ? (<Spinner/>) : (<Button type="submit" color="primary" variant="contained" size="small" disabled={alreadySent}>Send Invoice</Button>)}
      </li>
      <li style={{ color: success ? 'green' : 'red' }}>{ message }</li>
    </ul>
    </form>

  </div>);
};

const Options = (props: IInvoice) => {

  const [ doSubmit, { data, error, loading }] = useMutation(SET_INVOICE_PROPS);
  const { success, message } = get(data, 'updateInvoiceProps', {});

  if(error) {
    alert(error.toString());
  }

  function submit(e: any) {
    e.preventDefault();

    const data = new FormData(e.target);
    doSubmit({ variables: {
      id: props.id,
      paidAt: data.get('paidDate'),
      notes: data.get('notes'),
      commissionStatementDate: data.get('commissionStatementDate')
    }});
  }

  return (
    <div style={{ marginLeft: '2em'}}>
      <h3>Properties</h3>
      <form onSubmit={submit}>
        <ul>
          <li>
            <input name="paidDate" id="paidDate" type="date" placeholder="Invoice Paid Date" defaultValue={props.paidAt as string}/>
            <input type="button" value="Clear Paid Date" onClick={() => {(document.getElementById("paidDate") as any).value = ""; }}/>
          </li>
          <li>
            <input name="commissionStatementDate" id="commissionStatementDate" type="date" placeholder="Commission Statement Date" defaultValue={props.commissionStatementRaisedAt as string}/>
            <input type="button" value="Clear Commission Statement Date" onClick={() => {(document.getElementById("commissionStatementDate") as any).value = ""; }}/>
          </li>
          <li style={{ marginTop: '1em' }}><TextareaAutosize name="notes" rows="10" cols={50} placeholder="Notes...." defaultValue={props.notes as string}></TextareaAutosize></li>
          <li style={{ marginTop: '1em' }}>
            { loading ? (<Spinner/>) : (<Button type="submit" color="primary" variant="contained" size="small">Update properties</Button>) }
          </li>
          <li style={{ color: success ? 'green' : 'red', marginTop: '0.5em' }}>{ message }</li>
        </ul>
      </form>
    </div>
  );
}

export default ViewInvoice;
