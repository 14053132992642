import React from 'react';
import Grid from '@material-ui/core/Grid';

export default () => {
  return (
    <Grid style={{ width: '400px' }}>
      <div style={{ margin: '0 auto' }}>Are you sure you want to delete this person?</div>
    </Grid>
  );
}
