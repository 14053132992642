function create(type: string, ...argNames: [string]) {
  return function(...args: any) {
    console.log(argNames);
    console.log(args);
    const action: any = { type };
    argNames.forEach((arg, index) => {
      action[argNames[index]] = args[index]
    });
    console.log('action');
    console.log(action);
    return action;
  }
}

export const ACTIONS = {
  SEARCH_SET_FILTER: 'SEARCH_SET_FILTER',
  A: 'A',
  B: 'B'
}

export const SearchSetFilter = (filter: string, type: string) => {
  return { type: ACTIONS.SEARCH_SET_FILTER, filter, filterType: type };
}//create(ACTIONS.SEARCH_SET_FILTER, 'filter');
