import React from 'react';
import { useHistory } from 'react-router-dom';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Contacts from '@material-ui/icons/Contacts';
import Label from '@material-ui/icons/Label';
import LocalOffer from '@material-ui/icons/LocalOffer';
import Receipt from '@material-ui/icons/Receipt';
import Unarchive from '@material-ui/icons/Unarchive';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import './App.scss';

import Routes from './routes';
import SearchInput from './components/search/input';

const useStyles = makeStyles(() => createStyles({
  root: {
    display: 'flex',
		position: 'fixed',
    top: 0,
		left: 0,
		right: 0,
		bottom: 0
  },
  sidebar: {
    order: 1,
		width: '80px',
		backgroundColor: '#0B111D',
		color: '#fff'
  },
  main: {
    order: 2,
    flexGrow: 1,
  },
	header: {
		backgroundColor: '#fff',
		padding: '0.5em 1em',
		borderBottom: '1px solid #efefef'
	},
	logo: {
		textAlign: 'center',
    padding: '1.5em',
    fontWeight: 'bold'
	},
	content: {
		display: 'flex',
		height: '100%',
    //flex: '1 1 auto',
    overflowY: 'auto'
	},
	navList: {
		textAlign: 'center'
	},
	navItem: {
		fontSize: '0.9em',
		fontWeight: 300,
		padding: '0.8em 0',
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: '#232833'
		}
	},
	navItemText: {
		display: 'block',
		marginTop: '0.4em'
	},
	contactListContainer: {
		backgroundColor: '#fff',
		borderRight: '1px solid #efefef',
		height: '100%'
	}
}));

export default () => {
  const classes = useStyles();
  const history = useHistory();
  //const theme = useTheme();
  //const [navOpen, setNavOpen] = useState(false);

	/*

		<Box>
			<Container maxWidth="xl">
				<Routes />
			</Container>
		</Box>
   */

  /*

					<li className={ classes.navItem }>
						<Contacts/>
						<span className={ classes.navItemText }>Contacts</span>
					</li>
					<li className={ classes.navItem }>
						<Label/>
						<span className={ classes.navItemText }>Tags</span>
          </li>
   */


  return (
		<div className={ classes.root }>
			<div className={ classes.sidebar }>
				<div className={ classes.logo }>CRM</div>
        <ul className={ classes.navList }>
          <li className={ classes.navItem } onClick={ () => history.push('/new-person') }>
						<PersonAddIcon/>
						<span className={ classes.navItemText }>New Person</span>
					</li>
          <li className={ classes.navItem } onClick={ () => history.push('/list-tags') }>
						<LocalOffer/>
						<span className={ classes.navItemText }>Tags</span>
					</li>
          <li className={ classes.navItem } onClick={ () => history.push('/new-invoice') }>
						<AddShoppingCartIcon/>
						<span className={ classes.navItemText }>New Invoice</span>
					</li>
          <li className={ classes.navItem } onClick={ () => history.push('/list-invoices') }>
						<Receipt/>
						<span className={ classes.navItemText }>Invoices</span>
					</li>
				</ul>
			</div>
			<div className={ classes.main }>
        <div className={classes.header}>

		      <Grid container spacing={1} alignItems="flex-end">
            <SearchInput history={history.location.search}/>
          </Grid>
				</div>
				<div className={classes.content}>
					<Routes />
				</div>
			</div>
		</div>
  );
}
