import React from 'react';
import Grid from '@material-ui/core/Grid';

import './style.scss';

export interface ISculpture {
  onSelect: (id: number) => void
  onDelete: (id: number) => void
	id: number
	name: string
	exhibition: {
		id: number
		name: string
	}
	material: string
	location: string
	width: number
	depth: number
	height: number
	weight: number
	catalogNumber: string
	price: number
	currency: string
  displayed: boolean | null
  order: number | null
	files: [{
		id: number
		name: string
		size: number
		bucket: string
		key: string
		url: string
		createdAt: string
  }]
  videos: [string]
  additionalInfo: string
}

export default (props: ISculpture) => {
	const {
		id, name, material, location, width, depth, height,
    catalogNumber, price, files, displayed, onSelect,
    additionalInfo, order, onDelete
  } = props;

  /*
	const {
		id, name, exhibition, material, location, width, depth, height, weight,
		catalogNumber, price, currency, files, displayed
  } = sculpture;
  */

	return (
		<Grid container spacing={1} style={{ border: '1px solid #f1f1f1', background: '#fff', padding: '1em' }}>
			<Grid item xs={3} style={{ textAlign: 'center' }}>
				{ files && files.length >0 ? (<img src={files[0].url} alt="sculpture" width="130" style={{ border: '1px solid #55545f' }}/>) : (<span>No Photos</span>) }
			</Grid>
			<Grid item xs={9}>
				<table className="sculpture-single-table">
					<tbody>
						<tr>
							<td>Name:</td>
              <td>{name ? name : '<no-name>' }</td>
						</tr>
						<tr>
							<td>Material:</td>
							<td>{material}</td>
						</tr>
						<tr>
							<td>Dimensions:</td>
							<td>h: {height} w: {width} d: {depth}</td>
						</tr>
						<tr>
							<td>Add. Info:</td>
              <td>{additionalInfo}</td>
						</tr>
						<tr>
							<td>Location:</td>
							<td>{location}</td>
						</tr>
						<tr>
							<td>Catalog No:</td>
							<td>{catalogNumber}</td>
						</tr>
						<tr>
							<td>Price:</td>
							<td>{price ? price.toFixed(2) : '' }</td>
						</tr>
						<tr>
							<td>Displayed:</td>
							<td>{ displayed ? 'Yes':'No' }</td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td>
                <span onClick={() => { onDelete(id); }} style={{ color: 'red', textDecoration: 'underline', cursor: 'pointer' }}>Delete</span>
                &nbsp;
                <span onClick={() => { onSelect(id); }} style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}>Edit</span>
              </td>
            </tr>
					</tbody>
				</table>
			</Grid>
		</Grid>
	)
}
