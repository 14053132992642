import React from 'react';
import ReactDOM from 'react-dom';

import { get } from 'lodash';
import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloProvider } from '@apollo/react-hooks';
import { onError } from 'apollo-link-error';

import App from './App';
import Login from './views/login';
import * as serviceWorker from './serviceWorker';

import { Provider } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import configureStore, { history } from './configureStore';


import InvoiceView from './views/invoiceView';

import { getToken } from './lib/auth';

export const API_SERVER = process.env.REACT_APP_API_SERVER || 'http://localhost:4001';

const httpLink = createHttpLink({
	uri: `${API_SERVER}/graphql`,
});


const ProtectedRoute = ({ component: Component, ...args }: any) => {
	const authorised = getToken();

	return (<Route {...args} render={(props) => (
		authorised ? <Component {...props} />
		: <Redirect to="/login"/>
	)}/>);
};

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = getToken();
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    }
  }
});

const httpErrorHandler = onError((a) => {
  console.log('Network Error');
  if(a.graphQLErrors && a.graphQLErrors.length >0) {
    console.log('GraphQL Query errors')
    console.log(a.graphQLErrors);
    console.log(get(a, 'graphQLErrors[0].extensions.code', false));

    if(get(a, 'graphQLErrors[0].extensions.code', false) === "UNAUTHENTICATED") {
      localStorage.removeItem('crm2-key');
      if(process.env.REACT_APP_CRM_ENVIRONMENT === 'development') {
        window.location.replace('http://192.168.1.212:3000/login');
      } else {
        window.location.replace('https://crm.onformsculpture.co.uk/login');
      }
    }
  }

    /*
  console.log(a.networkError);
  console.log(get(a, 'networkError[0].extensions.code', false));
  if(get(a, 'networkError[0].extensions.code', false) === "UNAUTHENTICATED") {
    if(process.env.REACT_APP_CRM_ENVIRONMENT === 'development') {
      window.location.replace('http://localhost:3000/login');
    } else {
      window.location.replace('https://crm.onformsculpture.co.uk/login');
    }
  }
     */

/*
  if(a.networkError && (a.networkError as any).statusCode && (a.networkError as any).statusCode ===401) {
    if(process.env.REACT_APP_CRM_ENVIRONMENT === 'development') {
      window.location.replace('http://localhost:3000/login');
    } else {
      window.location.replace('https://crm.onformsculpture.co.uk/login');
    }
  }
  */

  //console.log((a.networkError as any).statusCode);
  //console.log(a);
  //if(a.networkError && (a.networkError as any).statusCode ===401) {
  //localStorage.removeItem('crm2-key');
  //}
});

function stripTypenames(obj: any, propToDelete: string) {
  for (const property in obj) {
    if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
      delete obj.property;
      const newData = stripTypenames(obj[property], propToDelete);
      obj[property] = newData;
    } else {
      if (property === propToDelete) {
        delete obj[property];
      }
    }
  }
  return obj;
}
  /*
const removeTypenameMiddleware = new ApolloLink(
  (operation: OperationInterface, forward: ()=>voil | null) => {
    if (operation.variables) {
      operation.variables = stripTypenames(operation.variables, '__typename');
      return forward ? forward(operation) : null;
    }
  },
);
   */

const client = new ApolloClient({
  link: authLink.concat(httpErrorHandler).concat(httpLink),
  //link: [].concat(httpErrorHandler, authLink, httpLink),
  //link: httpErrorHandler.concat([authLink, httpLink]),
  //link: new ApolloLink().concat(authLink).concat(httpLink).concat(httpErrorHandler),
  //link: from[authLink, httpLink, httpErrorHandler],
  cache: new InMemoryCache()
});

const store = configureStore();

history.listen((loc) => { console.log('history changed', loc); });

ReactDOM.render(<Provider store={store}>
	<ApolloProvider client={client}>
		<ConnectedRouter history={history}>
			<Switch>
				<Route path="/login" component={Login}/>
        <ProtectedRoute path="/invoice/:id" component={InvoiceView} />
				<App />
			</Switch>
		</ConnectedRouter>
	</ApolloProvider>
</Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
