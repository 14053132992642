import { useReducer } from 'react';
import { API_SERVER } from '../index';

interface IUploadState {
  complete: boolean
  started: boolean
  processing: boolean
  error: any
  data: any
  progress: {
    loaded: number
    total: number
    pct: number
  }
}


function reducer(state: IUploadState, action: any) {
  const { payload, type } = action;
  switch(type) {
    case 'complete':
      return Object.assign({}, state, { complete: true, processing: false, started: false, data: JSON.parse(payload) });
    case 'progress':
    const pct = ((payload.loaded/payload.total) *100);
    return Object.assign({}, state, {
      processing: (pct >= 100) ? true : false,
      progress: {
        loaded: payload.loaded,
        total: payload.total,
        pct
      }
    });
    case 'start':
      return Object.assign({}, state, { started: true });
    case 'error':
      return Object.assign({}, state, { error: payload });
    default:
      return state;
  }
}

export default (endpoint: string, options?: any): Promise<any> | any => {
  const [state, dispatch] = useReducer(reducer, {
    started: false, complete: false, error: undefined, data: undefined, processing: false,
    progress: { loaded: 0, total: 0, pct: 0 }
  });

  const send = (file: any) => {
    return new Promise((resolve, reject) => {
      const req = new XMLHttpRequest();

      req.upload.addEventListener('progress', event => {
        dispatch({ type: 'progress', payload: event });
      });

      req.addEventListener('load', () => {
        dispatch({ type: 'complete', payload: req.response });
        console.log('complete');
        console.log(state);
        return resolve(true);
      });

      req.upload.addEventListener('error', event => {
        console.log(event);
        dispatch({ type: 'error', payload: JSON.stringify(event) });
        return reject();
      });

      req.addEventListener('loadstart', () => {
        dispatch({ type: 'start' });
      });

      const formData = new FormData();
      formData.append('file', file, file.name);

      req.open('POST', `${API_SERVER}/upload`);
      req.send(formData);
    });
  }

  return {
    send,
    started: state.started,
    progress: state.progress,
    complete: state.complete,
    processisng: state.processing,
    error: state.error,
    data: state.data
  };
}
