import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
/*
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
*/

//import { createStyles, makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';

import { get, kebabCase } from 'lodash';

function Elem(props: { data: any, params: { text: string, type: string } }): JSX.Element {
  console.log(props);
  const history = useHistory();


  function loadClient(id: number) {
    localStorage.setItem(`ofcrm-last-selected-client-${kebabCase(props.params.text)}-${props.params.type}`, id.toString());
    history.push(`/client/?id=${id}`);
  }

  if(props.params.text && props.data.clients.length ===0) {
    return (<div style={{ margin: '1em', textAlign: 'center' }}>
      {props.data.clients.length} records found for "{props.params.text}"
    </div>);
  }

  if(!props.params.text) {
    return (<div style={{ margin: '1em', textAlign: 'center' }}>
      Please search for a person or tag
    </div>);
  }

  function getText(row: any) {
    return `${row.name.first} ${row.name.last}`
  }

	return (
    <List disablePadding key="clientList" style={{ marginBottom: '100px' }}>
      <ListItem>
        <ListItemText style={{ textAlign: 'center', color: '#111' }}>Total Results: {props.data.clients.length}</ListItemText>
      </ListItem>
			{ props.data.clients.map((row: any) => {
        let selectedColor = '#fff';
        const lastSelectedClient = localStorage.getItem(`ofcrm-last-selected-client-${kebabCase(props.params.text)}-${props.params.type}`);

        if(lastSelectedClient) {
          if(lastSelectedClient === row.id.toString()) {
            selectedColor = '#DDF0B2';
          }
        }

				const initials = `${get(row, 'name.first[0]', '?')}${get(row, 'name.last[0]', '?')}`.toUpperCase();

				return (
					<ListItem button style={{ backgroundColor: selectedColor }} key={`client-row-${row.id}`} onClick={ () => loadClient(row.id) }>
						<ListItemAvatar><Avatar style={{ backgroundColor: '#7cbf1c' }}>{ initials }</Avatar></ListItemAvatar>
						<ListItemText>{ getText(row) }</ListItemText>
					</ListItem>);
			})}
		</List>
	)
}

export default Elem;
