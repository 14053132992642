import * as React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import LoginForm from '../components/login/loginForm';
import Paper from '@material-ui/core/Paper';

export const Login = () => {
	return (
    <Container>
      <Grid container direction="column" justify="center" alignItems="center" style={{ minHeight: '100vh' }}>
        <Grid item style={{ width: '250px' }}>
          <Paper style={{ padding: '1.5em' }}>
            <LoginForm/>
          </Paper>
        </Grid>
      </Grid>
    </Container>
	);
}

export default Login;
