import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import InvoiceView from '../components/invoice/view';

function InvoiceViewContainer() {

  return (
    <Container>
      <Grid container direction="row" justify="center" alignItems="center"  spacing={2}>
        <Grid item xs={10} style={{ backgroundColor: '#fff' }}>
            <InvoiceView/>
        </Grid>
      </Grid>
    </Container>
  )
}

export default InvoiceViewContainer;
