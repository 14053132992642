import React from 'react';

interface IAddress {
  id: number
  type: string
  name: string
  line1: string
  line2: string
  line3: string
  line4: string
  line5: string
  city: string
  county: string
  postcode: string
  country: string
}

interface IBuyer {
  id: number
  fullName: string
  name: {
    salutation: string
    first: string
    middle: string
    last: string
    suffix: string
  }
  addresses: [IAddress] | null
}

interface IProps {
  buyer: IBuyer
  onAddressChange: (address: any) => void
}

function buildAddressOption(props: IAddress, index: number) {
  const type = props.type ? props.type : `Address ${index}`;
  return (<option value={props.id} key={`at${type}`}>{type}</option>);
}

export default (props: IProps) => {
  const { buyer } = props;

  const name = `${buyer.name.first} ${buyer.name.last}`;

  return (<div style={{ marginTop: '0.8em' }}>
    <select onChange={props.onAddressChange}>
      <option value="null" key={`canull`}>Choose an Address</option>
      { buyer.addresses && buyer.addresses.map(buildAddressOption) }
    </select>
  </div>);
}
