import React from 'react';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';

interface IProps {
  onChange: (option: string) => (event: any) => void //any// (options: { removeCommission: boolean, discount: number }) => void
  removeCommission: boolean
  discount: null | number
}

export default (props: IProps) => {
  return (<Grid container style={{ marginTop: '1em', display: 'none' }}>

      <Grid xs={3} item>
        <TextField id="discount" label="Discount %" onChange={props.onChange('discount')} value={props.discount}/>
      </Grid>

      <Grid xs={3} item>
        <FormControlLabel
        control={
          <Checkbox
            checked={props.removeCommission}
            onChange={props.onChange('removeCommission')}
            name="removeCommission"
            color="primary"
          />
        }
        label="Remove Commission"
      />
      </Grid>

    </Grid>);
}
