import React, { useMemo, useState } from 'react';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { uniqBy, sortBy } from 'lodash';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckIcon from '@material-ui/icons/CheckCircle';

import { formatCurrency } from './lib';
import Spinner from '../loader/spinner';
import { EXHIBITION_ARTISTS, GET_TEMPORARY_TOKEN, LIST_INVOICES } from '../../queries';
import './list.scss';
import { Button, Input, MenuItem, Select, TextField } from '@material-ui/core';

interface Invoice {
  id: number
  ClientId: number
  ExhibitionId: number
  ArtistId: number;
  invoiceNumber: string
  sculpture: { name: string, catalogNumber: string }
  sculptorName: string
  buyerName: string
  gross: number
  net: number
  vat: number
  commission: number
  artistPayment: number
  createdAt: string
  sentAt: string
  paidAt: string
  commissionStatementRaisedAt: string
}

const InvoiceList = () => {
  const exhibitions = [
    { name: 'onform-22', id: 11 },
    { name: 'onform-21', id: 10 },
    { name: 'onform-20', id: 1 },
    { name: 'onform-18', id: 2 },
  ];

  const [exhibition, setExhibition] = useState(exhibitions[0].id);
  const [artist, setArtist] = useState(0);
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const { data, loading, error } = useQuery(LIST_INVOICES, { variables: { exhibition, artistId: artist }});
  const { data: exhibitionArtistData, loading: exhibitionArtistLoading, error: exhibitionArtistError } = useQuery(EXHIBITION_ARTISTS, { variables: { exhibitionId: exhibition }});

  const { data: tempTokenData, loading: tempTokenLoading, error: tempTokenError } = useQuery(GET_TEMPORARY_TOKEN);

  if(loading || exhibitionArtistLoading || tempTokenLoading) { return (<Spinner/>); }

  return (
    <div className="invoiceList">
      <Select onChange={ (e) => { setExhibition(parseInt(e.target.value as string, 10)); setArtist(0) }} defaultValue={exhibition}>
        { exhibitions.map(r => (<MenuItem value={r.id} selected={exhibition === r.id}>{r.name}</MenuItem>)) }
      </Select>

      <Select onChange={ (e) => setArtist(parseInt(e.target.value as string, 10)) } defaultValue={artist} style={{ marginLeft: '1em' }}>
        <MenuItem value={0}>All Artists</MenuItem>
        {exhibitionArtistData.exhibitionArtists.map((row: { id: number, name: string }) => (<MenuItem value={ row.id }>{ row.name } </MenuItem>))}
      </Select>

      <Totals invoices={data.listInvoices} count={data.sculptureCount} artist={artist}/>

      <div>
        <form method="GET" action="https://crm.onformsculpture.co.uk/api/export/invoices" style={{ display: 'inline-block'}}>
          <input type="hidden" name="exhibition" value={exhibition}/>
          <input type="hidden" name="artist" value={artist}/>
          <input type="hidden" name="auth" value={ tempTokenData.temporaryAuth.token}/>
          <Button color="primary" variant="contained" type="submit">Export Invoices</Button>
        </form>

        { artist >0 && (
        <form method="GET" action="https://crm.onformsculpture.co.uk/api/export/commissionStatement" style={{ display: 'inline-block', marginLeft: '1em'}}>
          <input type="hidden" name="exhibition" value={exhibition}/>
          <input type="hidden" name="artist" value={artist}/>
          <input type="hidden" name="auth" value={ tempTokenData.temporaryAuth.token}/>
          <TextField name="nextInvoiceNumber" variant="outlined" size="small" placeholder="Next #" style={{ marginRight: '1em', width: '6em' }} value={invoiceNumber} onChange={(e) => setInvoiceNumber(e.currentTarget.value)} />
          <Button color="primary" disabled={invoiceNumber === ''} variant="contained" type="submit">{ exhibitionArtistData.exhibitionArtists.find((a: any) => (a.id === artist)).name} Commission Statement</Button>
        </form>)}
      </div>

      <table style={{ width: '100%' }}>
        <thead>
          <tr>
            <th style={{ textAlign: 'center', width: '60px' }}>#</th>
            <th>Buyer</th>
            <th>Artist</th>
            <th>Sculpture</th>
            <th>Catalog Number</th>
            <th style={{ textAlign: 'center' }}>Price</th>
            <th style={{ textAlign: 'center' }}>Commission</th>
            <th style={{ textAlign: 'center' }}>Artists Payment</th>
            <th>Created</th>
            <th>Sent</th>
            <th>Paid</th>
            <th>CS</th>
          </tr>
        </thead>
        <tbody>
          {data.listInvoices.map((row: Invoice) => <Row {...row }/>)}
        </tbody>
      </table>
    </div>
  );
}

function Row(props: Invoice) {
  return (
    <tr>
      <td style={{ textAlign: 'center' }}>
        <Link to={`/invoice/${props.id}`} target="_blank">{props.invoiceNumber}</Link>
      </td>
      <td><Link to={`/client/?id=${props.ClientId}`}>{props.buyerName}</Link></td>
      <td><Link to={`/client/?id=${props.ArtistId}`}>{props.sculptorName}</Link></td>
      <td>{props.sculpture.name}</td>
      <td>{props.sculpture.catalogNumber}</td>
      <td style={{ textAlign: 'center' }}>{formatCurrency(props.gross)}</td>
      <td style={{ textAlign: 'center' }}>{formatCurrency(props.commission)}</td>
      <td style={{ textAlign: 'center' }}>{formatCurrency(props.artistPayment)}</td>
      <td>{moment(props.createdAt).format('DD-MM-YY HH:mm')}</td>
      <td>
        <Link to={`/invoice/${props.id}`} target="_blank">
          {(props.ExhibitionId <11 || props.sentAt) ? (<CheckIcon style={{ color: 'green'}}/>) : (<CancelIcon color="error"/>)}
        </Link>
      </td>
      <td>
        <Link to={`/invoice/${props.id}`} target="_blank">
          {(props.ExhibitionId <11 || props.paidAt) ? (<CheckIcon style={{ color: 'green'}}/>) : (<CancelIcon color="error"/>)}
        </Link>
      </td>
      <td>
        <Link to={`/invoice/${props.id}`} target="_blank">
          {(props.ExhibitionId <11 || props.commissionStatementRaisedAt) ? (<CheckIcon style={{ color: 'green'}}/>) : (<CancelIcon color="error"/>)}
        </Link>
      </td>
    </tr>
  );
}

function Totals(props: { invoices: [Invoice], count: { count: number }, artist: number }) {
  let sold=0, commission=0, artistPayment=0;

  props.invoices.forEach(i => {
    sold = sold + i.gross;
    commission = commission + i.commission;
    artistPayment = artistPayment + i.artistPayment;
  });

  return (
    <div className="totals">
      <h5>Totals</h5>
      <table>
        <tr>
          <td>Sold</td>
          <td><strong>{props.invoices.length}</strong>{props.artist ===0 && (<> of <strong>{props.count.count}</strong></>)}</td>
        </tr>
        <tr>
          <td>Total Billed</td>
          <td style={{ fontWeight: 'bold' }}>{formatCurrency(sold)}</td>
        </tr>
        <tr>
          <td>Artist Payment</td>
          <td style={{ fontWeight: 'bold' }}>{formatCurrency(artistPayment)}</td>
        </tr>
        <tr>
          <td>Commission</td>
          <td style={{ fontWeight: 'bold' }}>{formatCurrency(commission)}</td>
        </tr>
      </table>
    </div>
  );
}

export default InvoiceList;
