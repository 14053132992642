import React from 'react';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

export default () => (
<Grid container direction="row" justify="center" alignItems="center">
  <Grid item>
    <CircularProgress/>
  </Grid>
</Grid>)
