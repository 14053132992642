import React from 'react';
import { chain, isNull } from 'lodash';

interface IProps {
  address: null | object
}

export default (props: IProps) => {
  if(isNull(props.address)) { return (<></>); }

  const lines = chain(props.address!).omit(['__typename', 'id', 'type'])
    .keys()
    .map((key: string) => {
      return (<li key={`al-${key}`}>{(props.address as any)[key]}</li>);
    }).value();

  return (<ul>{lines}</ul>);
}
