import React from 'react';
import { get } from 'lodash';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';

export default (props: any) => {
  console.log('nameInput');
  console.log(props);
  return (
    <Grid style={{ width: '400px' }}>
      <TextField name="salutation" label="Salutation" fullWidth onChange={props.onChange} value={get(props, 'values.salutation', '')}/>
      <TextField name="first" label="First name" fullWidth onChange={props.onChange} value={get(props, 'values.first', '')}/>
      <TextField name="middle" label="Middle name" fullWidth onChange={props.onChange} value={get(props, 'values.middle', '')}/>
      <TextField name="last" label="Last name" fullWidth onChange={props.onChange} value={get(props, 'values.last', '')}/>
      <TextField name="suffix" label="Suffix" fullWidth onChange={props.onChange} value={get(props, 'values.suffix', '')}/>
    </Grid>
  );
}
