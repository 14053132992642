import { Record } from 'immutable';
import { ACTIONS } from './actions';

interface IReduxAction {
  type: string;
  [key: string]: any;
}

export interface IState {
  filter: null | string;
  loading: boolean;
  search: any;
}

export const INITIAL_STATE: any = {
  filter: null,
  filterType: 'name',
  loading: false,
  search: {}
};

export class State extends Record(INITIAL_STATE){
  constructor(params?: IState) {
    params ? super(params) : super();
  }

  with(values: IState) {
    return this.merge(values) as this;
  }
}

function search_setFilter(state: State, action: IReduxAction) {
  console.log('search_setFilter');
  console.log(action);
  return state.set('filter', action.filter).set('filterType', action.filterType).set('loading', true);
}

export const search = (state: State = new State(INITIAL_STATE), action: IReduxAction) => {
  switch(action.type) {
    case ACTIONS.SEARCH_SET_FILTER:
      console.log('--- here');
      return search_setFilter(state, action);
    default:
      return state;
  }
}
