import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { EditorState, ContentState, convertToRaw, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';

import Upload from '../upload/upload';

export default (props: any) => {
  const [portrait, setPortrait] = useState(props.values.portrait);
  const [mainImage, setMainImage] = useState(props.values.mainImage);
  const blocks = convertFromHTML(props.values.webBio || '<p></p>');
  const [editorState, setEditorState] = useState(EditorState.createWithContent(ContentState.createFromBlockArray(blocks.contentBlocks, blocks.entityMap)));

  function handleUpload(name: string) {
    return (data: any) => {
      console.log('handleUploadComplete', data);
      if(name === 'portrait') {
        setPortrait(data.url);
      }

      if(name === 'mainImage') {
        setMainImage(data.url);
      }

      props.onChange({ target: { name, value: data.url } }, false);
    }
  }

  function editorStateChange(event: any) {
    const raw = convertToRaw(event.getCurrentContent());
    const markup = draftToHtml(raw);
    props.onChange({ target: { name: 'webBio', value: markup } });
    setEditorState(event);
  }

  const toolbar = {
    options: ['inline', 'link'],
    link: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    popupClassName: undefined,
    dropdownClassName: undefined,
    showOpenOptionOnHover: true,
    defaultTargetOption: '_blank',
    options: ['link', 'unlink'],
    link: { className: undefined },
    unlink: { className: undefined },
    linkCallback: undefined
  },
  }

  return (<div style={{ width: '550px' }}>

    <TextField fullWidth value={props.values.displayName || ''} name="displayName" label="Display name" onChange={props.onChange}/>

    <div style={{ border: '1px solid #f1f1f1' }}>
      <Editor editorState={editorState} toolbar={toolbar} onEditorStateChange={editorStateChange}/>
    </div>

    <TextField fullWidth name="website" label="Website URL" value={props.values.website || ''} onChange={props.onChange}/>

    <TextField fullWidth name="video" label="Video URL" value={props.values.video || ''} onChange={props.onChange}/>

    <div style={{ marginTop: '1em' }}>
      { portrait && (<img src={portrait} alt="portrait" width="180" />) }
      <Upload label={portrait ? 'Replace Portrait' : 'Upload Portrait'} name="portrait" onComplete={handleUpload('portrait')}/>
    </div>

    <div style={{ marginTop: '1em' }}>
      { mainImage && (<img src={mainImage} alt="mainImage" width="180" />) }
      <Upload label={mainImage ? 'Replace Main Image' : 'Upload Main Image'} name="mainImage" onComplete={handleUpload('mainImage')}/>
    </div>

  </div>);
}
