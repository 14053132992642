import { useState } from 'react';
import { compact, find, get } from 'lodash';
import { useHistory } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import EditIcon from '@material-ui/icons/Edit';
import Button from '@material-ui/core/Button';

import DialogWrapper from '../dialog/wrapper';
import Loader from '../loader/loader';
import SculptureList from '../sculpture/list';
import SculptorFields from './sculptorFields';

import ContactInfo from './contactInfo';
import Notes from './notes';
import Tags from './tags';
import NameInput from './nameInput';
import DeleteInput from './delete';

import InvoiceList from '../invoice/clientList';

import {
  GET_CLIENT_CONTACT_INFO,
  GET_SCULPTOR_FIELDS,
  GET_CLIENT_SCULPTURES,
  UPDATE_CLIENT,
  REMOVE_CLIENT,
  GET_CLIENT_INVOICES
} from '../../queries';

const TabPanel = (props: any) => {
  const { value, index, children } = props;

  return (
    <div hidden={value !== index}>
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
};

export const ClientView = (props: { data: { client: any } }) => {
  const history = useHistory();
  const [tabOpen, setTabOpen] = useState(0);
  const [editNameOpen, setEditNameOpen] = useState(false);
  const [deletePersonOpen, setDeletePersonOpen] = useState(false);
  console.log(props);

  const c = get (props, 'data.client');
  if(!c) { return (<div>Something went wrong</div>); }

  const client = props.data.client;
  console.log(client);

  function handleTabChange(event: any, newVal: any) {
    setTabOpen(newVal);
  }

  const nameArray = [
    client.name.salutation,
    client.name.first,
    client.name.last,
    client.name.suffix
  ];

  const SculptureListView = Loader()(SculptureList);
  const ContactInfoView = Loader()(ContactInfo);
  const SculptorFieldsView = Loader()(SculptorFields);
  const InvoiceListView = Loader()(InvoiceList);

  const editNameDialogParams = {
    title: 'Edit Name',
    open: editNameOpen,
    toggle: setEditNameOpen,
    onComplete: (data: any) => { console.log('onComplete editName'); console.log(data); setEditNameOpen(false); },
    formatter: (input: any) => ({
      id: input.id,
      name: {
        salutation: input.salutation,
        first: input.first,
        middle: input.middle,
        last: input.last,
        suffix: input.suffix
      }
    }),
    mutation: UPDATE_CLIENT,
    id: client.id,
    defaultVariables: client.name,
    toggleOnComplete: false
  }

  const deletePersonDialogParams = {
    title: 'Delete Person',
    open: deletePersonOpen,
    toggle: setDeletePersonOpen,
    onComplete: () => { history.push('/'); },
    mutation: REMOVE_CLIENT,
    id: client.id,
    toggleOnComplete: false,
    submitButtonLabel: "DELETE",
    submitButtonColor: "secondary"
  };

  const NameDialog = DialogWrapper(editNameDialogParams)(NameInput);
  const DeleteDialog = DialogWrapper(deletePersonDialogParams)(DeleteInput);

  const isSculptor = find(client.tags, t => {
    return new RegExp(/(onform-artist)|sculptor\d{2}/gim).test(t.name);
    });

  return (
    <Container maxWidth="xl" key={`client-${client.id}`}>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        spacing={2}
        style={{ margin: '0.7em 0', marginBottom: '120px' }}
      >
        <Grid item xs={12}>
          <Paper style={{ padding: '1em' }}>
            <div>
              <div style={{ textAlign: 'center' }}>
                <h3>{compact(nameArray).join(' ')} <EditIcon fontSize="small" style={{ cursor: 'pointer' }} onClick={() => setEditNameOpen(true) }/></h3>
                <div
                  className="text-small text-grey"
                  style={{ margin: '0.5em 0' }}
                >
                  Last updated: {client.updatedAt}
                </div>
                <Tags tags={client.tags} clientId={client.id} />
                <div>
                  <Button variant="outlined" size="small" color="secondary" onClick={ () => setDeletePersonOpen(true) }>Delete</Button>
                </div>
              </div>
              <Divider variant="middle" className="divider" />
              <div>
                <h5>Contact Information</h5>
                <ContactInfoView
                  query={GET_CLIENT_CONTACT_INFO}
                  params={{ id: props.data.client.id }}
                />
              </div>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          {isSculptor ? (
            <Paper>
              <Tabs value={tabOpen} onChange={handleTabChange}>
                <Tab label="Notes" />
                <Tab label="Sculptor Information" />
                <Tab label="Sculptures" />
                <Tab label="Invoices" />
              </Tabs>
              <TabPanel value={tabOpen} index={0}>
                <Notes notes={client.notes} id={client.id} />
              </TabPanel>
              <TabPanel value={tabOpen} index={1}>
                <SculptorFieldsView
                  query={GET_SCULPTOR_FIELDS}
                  params={{ id: props.data.client.id }}
                />
              </TabPanel>
              <TabPanel value={tabOpen} index={2}>
                <SculptureListView
                  query={GET_CLIENT_SCULPTURES}
                  params={{ id: props.data.client.id }}
                />
              </TabPanel>
              <TabPanel value={tabOpen} index={3}>
                <InvoiceListView
                  query={GET_CLIENT_INVOICES}
                  params={{ id: props.data.client.id }}
                />
              </TabPanel>
            </Paper>
          ) : (
            <Paper>
              <Tabs value={tabOpen} onChange={handleTabChange}>
                <Tab label="Notes" />
                <Tab label="Invoices" />
              </Tabs>
              <TabPanel value={tabOpen} index={0}>
                <Notes notes={client.notes.reverse()} id={client.id} />
              </TabPanel>
              <TabPanel value={tabOpen} index={1}>
                <InvoiceListView
                  query={GET_CLIENT_INVOICES}
                  params={{ id: props.data.client.id }}
                />
              </TabPanel>
            </Paper>
          )}
        </Grid>
      </Grid>
      <NameDialog/>
      <DeleteDialog/>
    </Container>
  );
};

export default Loader()(ClientView);
